// tslint:disable
/**
 * DQOps Cloud
 * DQOps Cloud API
 *
 * The version of the OpenAPI document: v1
 * Contact: support@dqops.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as globalImportUrl from 'url';
import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface ContactRepliedToCampaignMessage
 */
export interface ContactRepliedToCampaignMessage {
    /**
     * 
     * @type {string}
     * @memberof ContactRepliedToCampaignMessage
     */
    linkedin?: string;
    /**
     * 
     * @type {string}
     * @memberof ContactRepliedToCampaignMessage
     */
    linkedin_profile_url?: string;
    /**
     * 
     * @type {string}
     * @memberof ContactRepliedToCampaignMessage
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof ContactRepliedToCampaignMessage
     */
    first_name?: string;
    /**
     * 
     * @type {string}
     * @memberof ContactRepliedToCampaignMessage
     */
    middle_name?: string;
    /**
     * 
     * @type {string}
     * @memberof ContactRepliedToCampaignMessage
     */
    last_name?: string;
    /**
     * 
     * @type {string}
     * @memberof ContactRepliedToCampaignMessage
     */
    company?: string;
    /**
     * 
     * @type {string}
     * @memberof ContactRepliedToCampaignMessage
     */
    title?: string;
    /**
     * 
     * @type {string}
     * @memberof ContactRepliedToCampaignMessage
     */
    industry?: string;
    /**
     * 
     * @type {string}
     * @memberof ContactRepliedToCampaignMessage
     */
    phone?: string;
    /**
     * 
     * @type {string}
     * @memberof ContactRepliedToCampaignMessage
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof ContactRepliedToCampaignMessage
     */
    website?: string;
    /**
     * 
     * @type {string}
     * @memberof ContactRepliedToCampaignMessage
     */
    twitter?: string;
    /**
     * 
     * @type {string}
     * @memberof ContactRepliedToCampaignMessage
     */
    education?: string;
    /**
     * 
     * @type {string}
     * @memberof ContactRepliedToCampaignMessage
     */
    location?: string;
    /**
     * 
     * @type {string}
     * @memberof ContactRepliedToCampaignMessage
     */
    connections?: string;
    /**
     * 
     * @type {Array<WeConnectExperience>}
     * @memberof ContactRepliedToCampaignMessage
     */
    experience?: Array<WeConnectExperience>;
    /**
     * 
     * @type {string}
     * @memberof ContactRepliedToCampaignMessage
     */
    tags?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ContactRepliedToCampaignMessage
     */
    campaigns?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof ContactRepliedToCampaignMessage
     */
    status?: string;
    /**
     * 
     * @type {string}
     * @memberof ContactRepliedToCampaignMessage
     */
    webhook_name?: string;
    /**
     * 
     * @type {string}
     * @memberof ContactRepliedToCampaignMessage
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof ContactRepliedToCampaignMessage
     */
    sent_at?: string;
    /**
     * 
     * @type {string}
     * @memberof ContactRepliedToCampaignMessage
     */
    message?: string;
    /**
     * 
     * @type {string}
     * @memberof ContactRepliedToCampaignMessage
     */
    thread?: string;
}
/**
 * 
 * @export
 * @interface ContactTaggedMessage
 */
export interface ContactTaggedMessage {
    /**
     * 
     * @type {string}
     * @memberof ContactTaggedMessage
     */
    linkedin?: string;
    /**
     * 
     * @type {string}
     * @memberof ContactTaggedMessage
     */
    linkedin_profile_url?: string;
    /**
     * 
     * @type {string}
     * @memberof ContactTaggedMessage
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof ContactTaggedMessage
     */
    first_name?: string;
    /**
     * 
     * @type {string}
     * @memberof ContactTaggedMessage
     */
    middle_name?: string;
    /**
     * 
     * @type {string}
     * @memberof ContactTaggedMessage
     */
    last_name?: string;
    /**
     * 
     * @type {string}
     * @memberof ContactTaggedMessage
     */
    company?: string;
    /**
     * 
     * @type {string}
     * @memberof ContactTaggedMessage
     */
    title?: string;
    /**
     * 
     * @type {string}
     * @memberof ContactTaggedMessage
     */
    industry?: string;
    /**
     * 
     * @type {string}
     * @memberof ContactTaggedMessage
     */
    phone?: string;
    /**
     * 
     * @type {string}
     * @memberof ContactTaggedMessage
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof ContactTaggedMessage
     */
    website?: string;
    /**
     * 
     * @type {string}
     * @memberof ContactTaggedMessage
     */
    twitter?: string;
    /**
     * 
     * @type {string}
     * @memberof ContactTaggedMessage
     */
    education?: string;
    /**
     * 
     * @type {string}
     * @memberof ContactTaggedMessage
     */
    location?: string;
    /**
     * 
     * @type {string}
     * @memberof ContactTaggedMessage
     */
    connections?: string;
    /**
     * 
     * @type {Array<WeConnectExperience>}
     * @memberof ContactTaggedMessage
     */
    experience?: Array<WeConnectExperience>;
    /**
     * 
     * @type {string}
     * @memberof ContactTaggedMessage
     */
    tags?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ContactTaggedMessage
     */
    campaigns?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof ContactTaggedMessage
     */
    status?: string;
    /**
     * 
     * @type {string}
     * @memberof ContactTaggedMessage
     */
    webhook_name?: string;
    /**
     * 
     * @type {string}
     * @memberof ContactTaggedMessage
     */
    tag?: string;
}
/**
 * DQOps Cloud data domain model that describes a data domain that was created in an ENTERPRISE DQOps Cloud account.
 * @export
 * @interface DataDomainModel
 */
export interface DataDomainModel {
    /**
     * Data domain name (technical name)
     * @type {string}
     * @memberof DataDomainModel
     */
    dataDomainName?: string;
    /**
     * Data domain display name, returns (default) for the default data domain that is identified by an empty string
     * @type {string}
     * @memberof DataDomainModel
     */
    displayName?: string;
    /**
     * The data domain creation timestamp.
     * @type {string}
     * @memberof DataDomainModel
     */
    createdAt?: string;
}
/**
 * DQOps Cloud user model that describes a user in multi-user SaaS deployments.
 * @export
 * @interface DqoUserModel
 */
export interface DqoUserModel {
    /**
     * Email that identifies the user.
     * @type {string}
     * @memberof DqoUserModel
     */
    email?: string;
    /**
     * Global account role assigned to the user.
     * @type {string}
     * @memberof DqoUserModel
     */
    accountRole?: DqoUserModelAccountRoleEnum;
    /**
     * Write-only property used to update the password. This field does not return the current password.
     * @type {string}
     * @memberof DqoUserModel
     */
    newPassword?: string;
    /**
     * Dictionary of roles assigned at a data domain level.
     * @type {{ [key: string]: string; }}
     * @memberof DqoUserModel
     */
    dataDomainRoles?: { [key: string]: string; };
}

/**
    * @export
    * @enum {string}
    */
export enum DqoUserModelAccountRoleEnum {
    Admin = 'admin',
    Editor = 'editor',
    Operator = 'operator',
    Viewer = 'viewer',
    None = 'none'
}
/**
    * @export
    * @enum {string}
    */
export enum DqoUserModelDataDomainRolesEnum {
    Admin = 'admin',
    Editor = 'editor',
    Operator = 'operator',
    Viewer = 'viewer',
    None = 'none'
}

/**
 * 
 * @export
 * @interface IdpTenantPairingKeyModel
 */
export interface IdpTenantPairingKeyModel {
    /**
     * 
     * @type {string}
     * @memberof IdpTenantPairingKeyModel
     */
    accountName?: string;
    /**
     * 
     * @type {string}
     * @memberof IdpTenantPairingKeyModel
     */
    domain?: string;
    /**
     * 
     * @type {string}
     * @memberof IdpTenantPairingKeyModel
     */
    pairingKey?: string;
}
/**
 * 
 * @export
 * @interface IdpTenantProvisioningModel
 */
export interface IdpTenantProvisioningModel {
    /**
     * 
     * @type {string}
     * @memberof IdpTenantProvisioningModel
     */
    accountName?: string;
    /**
     * 
     * @type {string}
     * @memberof IdpTenantProvisioningModel
     */
    tenantId?: string;
    /**
     * 
     * @type {number}
     * @memberof IdpTenantProvisioningModel
     */
    version?: number;
    /**
     * 
     * @type {string}
     * @memberof IdpTenantProvisioningModel
     */
    gcpRegionData?: string;
    /**
     * 
     * @type {string}
     * @memberof IdpTenantProvisioningModel
     */
    licenseType?: IdpTenantProvisioningModelLicenseTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof IdpTenantProvisioningModel
     */
    customMonthsLimit?: number;
    /**
     * 
     * @type {number}
     * @memberof IdpTenantProvisioningModel
     */
    customUsersLimit?: number;
    /**
     * 
     * @type {number}
     * @memberof IdpTenantProvisioningModel
     */
    customConnectionsLimit?: number;
    /**
     * 
     * @type {number}
     * @memberof IdpTenantProvisioningModel
     */
    customTablesLimit?: number;
    /**
     * 
     * @type {number}
     * @memberof IdpTenantProvisioningModel
     */
    customJobsLimit?: number;
    /**
     * 
     * @type {number}
     * @memberof IdpTenantProvisioningModel
     */
    customDataDomainsLimit?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof IdpTenantProvisioningModel
     */
    invitedAdmins?: Array<string>;
}

/**
    * @export
    * @enum {string}
    */
export enum IdpTenantProvisioningModelLicenseTypeEnum {
    FREE = 'FREE',
    PERSONAL = 'PERSONAL',
    TEAM = 'TEAM',
    ENTERPRISE = 'ENTERPRISE'
}

/**
 * 
 * @export
 * @interface NoteSetOnContactMessage
 */
export interface NoteSetOnContactMessage {
    /**
     * 
     * @type {string}
     * @memberof NoteSetOnContactMessage
     */
    linkedin?: string;
    /**
     * 
     * @type {string}
     * @memberof NoteSetOnContactMessage
     */
    linkedin_profile_url?: string;
    /**
     * 
     * @type {string}
     * @memberof NoteSetOnContactMessage
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof NoteSetOnContactMessage
     */
    first_name?: string;
    /**
     * 
     * @type {string}
     * @memberof NoteSetOnContactMessage
     */
    middle_name?: string;
    /**
     * 
     * @type {string}
     * @memberof NoteSetOnContactMessage
     */
    last_name?: string;
    /**
     * 
     * @type {string}
     * @memberof NoteSetOnContactMessage
     */
    company?: string;
    /**
     * 
     * @type {string}
     * @memberof NoteSetOnContactMessage
     */
    title?: string;
    /**
     * 
     * @type {string}
     * @memberof NoteSetOnContactMessage
     */
    industry?: string;
    /**
     * 
     * @type {string}
     * @memberof NoteSetOnContactMessage
     */
    phone?: string;
    /**
     * 
     * @type {string}
     * @memberof NoteSetOnContactMessage
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof NoteSetOnContactMessage
     */
    website?: string;
    /**
     * 
     * @type {string}
     * @memberof NoteSetOnContactMessage
     */
    twitter?: string;
    /**
     * 
     * @type {string}
     * @memberof NoteSetOnContactMessage
     */
    education?: string;
    /**
     * 
     * @type {string}
     * @memberof NoteSetOnContactMessage
     */
    location?: string;
    /**
     * 
     * @type {string}
     * @memberof NoteSetOnContactMessage
     */
    connections?: string;
    /**
     * 
     * @type {Array<WeConnectExperience>}
     * @memberof NoteSetOnContactMessage
     */
    experience?: Array<WeConnectExperience>;
    /**
     * 
     * @type {string}
     * @memberof NoteSetOnContactMessage
     */
    tags?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof NoteSetOnContactMessage
     */
    campaigns?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof NoteSetOnContactMessage
     */
    status?: string;
    /**
     * 
     * @type {string}
     * @memberof NoteSetOnContactMessage
     */
    webhook_name?: string;
    /**
     * 
     * @type {string}
     * @memberof NoteSetOnContactMessage
     */
    note?: string;
}
/**
 * 
 * @export
 * @interface PipedrivePersonIds
 */
export interface PipedrivePersonIds {
    /**
     * 
     * @type {boolean}
     * @memberof PipedrivePersonIds
     */
    newLeadOrDealCreated?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PipedrivePersonIds
     */
    ownerId?: number;
    /**
     * 
     * @type {number}
     * @memberof PipedrivePersonIds
     */
    personId?: number;
    /**
     * 
     * @type {number}
     * @memberof PipedrivePersonIds
     */
    organizationId?: number;
    /**
     * 
     * @type {string}
     * @memberof PipedrivePersonIds
     */
    leadId?: string;
    /**
     * 
     * @type {number}
     * @memberof PipedrivePersonIds
     */
    pipelineId?: number;
    /**
     * 
     * @type {number}
     * @memberof PipedrivePersonIds
     */
    dealId?: number;
    /**
     * 
     * @type {string}
     * @memberof PipedrivePersonIds
     */
    dealName?: string;
}
/**
 * Request object for refreshing a native table in the data quality data warehouse.
 * @export
 * @interface RefreshTableRequest
 */
export interface RefreshTableRequest {
    /**
     * Data domain name for ENTERPRISE accounts that support multiple data domain.
     * @type {string}
     * @memberof RefreshTableRequest
     */
    dataDomain?: string;
    /**
     * Target table to be refreshed.
     * @type {string}
     * @memberof RefreshTableRequest
     */
    table?: RefreshTableRequestTableEnum;
    /**
     * Optional list of connection names that were modified and the data should be refreshed.
     * @type {Array<string>}
     * @memberof RefreshTableRequest
     */
    connections?: Array<string>;
    /**
     * Optional list of full table names (schema.table) that were modified and the data should be refreshed.
     * @type {Array<string>}
     * @memberof RefreshTableRequest
     */
    tables?: Array<string>;
    /**
     * Optional list of dates of the first day of the month (for example: \'2023-02-01\') that were modified and the data should be refreshed.
     * @type {Array<string>}
     * @memberof RefreshTableRequest
     */
    months?: Array<string>;
    /**
     * Optional list of partitions that should be loaded from parquet files to the native tables in the data warehouse.
     * @type {Array<RefreshedPartitionModel>}
     * @memberof RefreshTableRequest
     */
    partitions?: Array<RefreshedPartitionModel>;
}

/**
    * @export
    * @enum {string}
    */
export enum RefreshTableRequestTableEnum {
    SensorReadouts = 'sensor_readouts',
    CheckResults = 'check_results',
    Errors = 'errors',
    ErrorSamples = 'error_samples',
    Statistics = 'statistics',
    Incidents = 'incidents',
    Sources = 'sources',
    Sensors = 'sensors',
    Rules = 'rules',
    Checks = 'checks',
    Settings = 'settings',
    Credentials = 'credentials',
    Dictionaries = 'dictionaries',
    Patterns = 'patterns'
}

/**
 * Identifies a partition that should be refreshed.
 * @export
 * @interface RefreshedPartitionModel
 */
export interface RefreshedPartitionModel {
    /**
     * Connection name that should be refreshed.
     * @type {string}
     * @memberof RefreshedPartitionModel
     */
    connection?: string;
    /**
     * Full table name (schema.table) that should be refreshed.
     * @type {string}
     * @memberof RefreshedPartitionModel
     */
    schemaTableName?: string;
    /**
     * The date of the first day of the month that should be refreshed. 
     * @type {string}
     * @memberof RefreshedPartitionModel
     */
    month?: string;
}
/**
 * Model returned by the DQOps Cloud with an AccessToken for accessing a folder within the landing zone of the tenant\'s data quality data warehouse.
 * @export
 * @interface TenantAccessTokenModel
 */
export interface TenantAccessTokenModel {
    /**
     * Google cloud access token.
     * @type {string}
     * @memberof TenantAccessTokenModel
     */
    accessToken?: string;
    /**
     * Tenant (customer) id.
     * @type {string}
     * @memberof TenantAccessTokenModel
     */
    tenantId?: string;
    /**
     * Google Cloud Storage bucket name for the tenant.
     * @type {string}
     * @memberof TenantAccessTokenModel
     */
    bucketName?: string;
    /**
     * Object name prefix (path) inside the bucket to store the data.
     * @type {string}
     * @memberof TenantAccessTokenModel
     */
    bucketPathPrefix?: string;
    /**
     * Access token expiration time (UTC).
     * @type {string}
     * @memberof TenantAccessTokenModel
     */
    expiresAt?: string;
    /**
     * GCP project id holding the data of the tenant.
     * @type {string}
     * @memberof TenantAccessTokenModel
     */
    billingProjectId?: string;
}
/**
 * Model that returns an AccessToken used by Looker Studio to query the data warehouse.
 * @export
 * @interface TenantQueryAccessTokenModel
 */
export interface TenantQueryAccessTokenModel {
    /**
     * 
     * @type {string}
     * @memberof TenantQueryAccessTokenModel
     */
    accessToken?: string;
    /**
     * 
     * @type {string}
     * @memberof TenantQueryAccessTokenModel
     */
    tenantId?: string;
    /**
     * 
     * @type {string}
     * @memberof TenantQueryAccessTokenModel
     */
    expiresAt?: string;
    /**
     * 
     * @type {string}
     * @memberof TenantQueryAccessTokenModel
     */
    billingProjectId?: string;
    /**
     * 
     * @type {string}
     * @memberof TenantQueryAccessTokenModel
     */
    dataSetName?: string;
    /**
     * 
     * @type {string}
     * @memberof TenantQueryAccessTokenModel
     */
    sensorReadoutsTableName?: string;
    /**
     * 
     * @type {string}
     * @memberof TenantQueryAccessTokenModel
     */
    checkResultsTableName?: string;
    /**
     * 
     * @type {string}
     * @memberof TenantQueryAccessTokenModel
     */
    errorsTableName?: string;
    /**
     * 
     * @type {string}
     * @memberof TenantQueryAccessTokenModel
     */
    statisticsTableName?: string;
    /**
     * 
     * @type {string}
     * @memberof TenantQueryAccessTokenModel
     */
    incidentsTableName?: string;
}
/**
 * 
 * @export
 * @interface UserLoginTicketGrantingTicketRequest
 */
export interface UserLoginTicketGrantingTicketRequest {
    /**
     * Tenant id.
     * @type {string}
     * @memberof UserLoginTicketGrantingTicketRequest
     */
    tid?: string;
    /**
     * Tenant group.
     * @type {number}
     * @memberof UserLoginTicketGrantingTicketRequest
     */
    tg?: number;
    /**
     * The base return url that is authorized.
     * @type {string}
     * @memberof UserLoginTicketGrantingTicketRequest
     */
    url?: string;
    /**
     * Client secret - a passcode used to sign and verify the token by the DQO instance, encoded with Base64 encodig.
     * @type {string}
     * @memberof UserLoginTicketGrantingTicketRequest
     */
    cs?: string;
}
/**
 * 
 * @export
 * @interface UserModel
 */
export interface UserModel {
    /**
     * 
     * @type {string}
     * @memberof UserModel
     */
    userId?: string;
    /**
     * 
     * @type {string}
     * @memberof UserModel
     */
    tenantId?: string;
    /**
     * 
     * @type {string}
     * @memberof UserModel
     */
    email?: string;
    /**
     * 
     * @type {number}
     * @memberof UserModel
     */
    tenantGroupId?: number;
}
/**
 * 
 * @export
 * @interface WeConnectContact
 */
export interface WeConnectContact {
    /**
     * 
     * @type {string}
     * @memberof WeConnectContact
     */
    linkedin?: string;
    /**
     * 
     * @type {string}
     * @memberof WeConnectContact
     */
    linkedin_profile_url?: string;
    /**
     * 
     * @type {string}
     * @memberof WeConnectContact
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof WeConnectContact
     */
    first_name?: string;
    /**
     * 
     * @type {string}
     * @memberof WeConnectContact
     */
    middle_name?: string;
    /**
     * 
     * @type {string}
     * @memberof WeConnectContact
     */
    last_name?: string;
    /**
     * 
     * @type {string}
     * @memberof WeConnectContact
     */
    company?: string;
    /**
     * 
     * @type {string}
     * @memberof WeConnectContact
     */
    title?: string;
    /**
     * 
     * @type {string}
     * @memberof WeConnectContact
     */
    industry?: string;
    /**
     * 
     * @type {string}
     * @memberof WeConnectContact
     */
    phone?: string;
    /**
     * 
     * @type {string}
     * @memberof WeConnectContact
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof WeConnectContact
     */
    website?: string;
    /**
     * 
     * @type {string}
     * @memberof WeConnectContact
     */
    twitter?: string;
    /**
     * 
     * @type {string}
     * @memberof WeConnectContact
     */
    education?: string;
    /**
     * 
     * @type {string}
     * @memberof WeConnectContact
     */
    location?: string;
    /**
     * 
     * @type {string}
     * @memberof WeConnectContact
     */
    connections?: string;
    /**
     * 
     * @type {Array<WeConnectExperience>}
     * @memberof WeConnectContact
     */
    experience?: Array<WeConnectExperience>;
    /**
     * 
     * @type {string}
     * @memberof WeConnectContact
     */
    tags?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof WeConnectContact
     */
    campaigns?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof WeConnectContact
     */
    status?: string;
    /**
     * 
     * @type {string}
     * @memberof WeConnectContact
     */
    webhook_name?: string;
}
/**
 * 
 * @export
 * @interface WeConnectExperience
 */
export interface WeConnectExperience {
    /**
     * 
     * @type {string}
     * @memberof WeConnectExperience
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof WeConnectExperience
     */
    title?: string;
    /**
     * 
     * @type {string}
     * @memberof WeConnectExperience
     */
    period?: string;
}

/**
 * AccessTokenIssueApi - axios parameter creator
 * @export
 */
export const AccessTokenIssueApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Issues a Google Storage access token for accessing the tenant\'s folder with rule results
         * @summary issueBucketCheckResultsRWAccessToken
         * @param {string} [dataDomain] The name of the data domain
         * @param {string} [owner] The email of account\&#39;s owner
         * @param {string} [tenantId] The tenant id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        issueBucketCheckResultsRWAccessToken: async (dataDomain?: string, owner?: string, tenantId?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/accesstokenissue/rw/check_results`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("APIKey")
                    : await configuration.apiKey;
                localVarHeaderParameter["APIKey"] = localVarApiKeyValue;
            }

            if (dataDomain !== undefined) {
                localVarQueryParameter['dataDomain'] = dataDomain;
            }

            if (owner !== undefined) {
                localVarQueryParameter['owner'] = owner;
            }

            if (tenantId !== undefined) {
                localVarQueryParameter['tenantId'] = tenantId;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Issues a Google Storage access token for accessing the tenant\'s folder with custom check definitions
         * @summary issueBucketChecksRWAccessToken
         * @param {string} [dataDomain] The name of the data domain
         * @param {string} [owner] The email of account\&#39;s owner
         * @param {string} [tenantId] The tenant id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        issueBucketChecksRWAccessToken: async (dataDomain?: string, owner?: string, tenantId?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/accesstokenissue/rw/checks`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("APIKey")
                    : await configuration.apiKey;
                localVarHeaderParameter["APIKey"] = localVarApiKeyValue;
            }

            if (dataDomain !== undefined) {
                localVarQueryParameter['dataDomain'] = dataDomain;
            }

            if (owner !== undefined) {
                localVarQueryParameter['owner'] = owner;
            }

            if (tenantId !== undefined) {
                localVarQueryParameter['tenantId'] = tenantId;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Issues a Google Storage access token for accessing the tenant\'s folder with credentials (.credentials folder)
         * @summary issueBucketCredentialsRWAccessToken
         * @param {string} [dataDomain] The name of the data domain
         * @param {string} [owner] The email of account\&#39;s owner
         * @param {string} [tenantId] The tenant id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        issueBucketCredentialsRWAccessToken: async (dataDomain?: string, owner?: string, tenantId?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/accesstokenissue/rw/credentials`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("APIKey")
                    : await configuration.apiKey;
                localVarHeaderParameter["APIKey"] = localVarApiKeyValue;
            }

            if (dataDomain !== undefined) {
                localVarQueryParameter['dataDomain'] = dataDomain;
            }

            if (owner !== undefined) {
                localVarQueryParameter['owner'] = owner;
            }

            if (tenantId !== undefined) {
                localVarQueryParameter['tenantId'] = tenantId;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Issues a Google Storage access token for accessing the tenant\'s folder with custom term dictionaries (dictionaries folder)
         * @summary issueBucketDictionariesRWAccessToken
         * @param {string} [dataDomain] The name of the data domain
         * @param {string} [owner] The email of account\&#39;s owner
         * @param {string} [tenantId] The tenant id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        issueBucketDictionariesRWAccessToken: async (dataDomain?: string, owner?: string, tenantId?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/accesstokenissue/rw/dictionaries`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("APIKey")
                    : await configuration.apiKey;
                localVarHeaderParameter["APIKey"] = localVarApiKeyValue;
            }

            if (dataDomain !== undefined) {
                localVarQueryParameter['dataDomain'] = dataDomain;
            }

            if (owner !== undefined) {
                localVarQueryParameter['owner'] = owner;
            }

            if (tenantId !== undefined) {
                localVarQueryParameter['tenantId'] = tenantId;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Issues a Google Storage access token for accessing the tenant\'s folder with error samples
         * @summary issueBucketErrorSamplesRWAccessToken
         * @param {string} [dataDomain] The name of the data domain
         * @param {string} [owner] The email of account\&#39;s owner
         * @param {string} [tenantId] The tenant id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        issueBucketErrorSamplesRWAccessToken: async (dataDomain?: string, owner?: string, tenantId?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/accesstokenissue/rw/error_samples`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("APIKey")
                    : await configuration.apiKey;
                localVarHeaderParameter["APIKey"] = localVarApiKeyValue;
            }

            if (dataDomain !== undefined) {
                localVarQueryParameter['dataDomain'] = dataDomain;
            }

            if (owner !== undefined) {
                localVarQueryParameter['owner'] = owner;
            }

            if (tenantId !== undefined) {
                localVarQueryParameter['tenantId'] = tenantId;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Issues a Google Storage access token for accessing the tenant\'s folder with errors
         * @summary issueBucketErrorsRWAccessToken
         * @param {string} [dataDomain] The name of the data domain
         * @param {string} [owner] The email of account\&#39;s owner
         * @param {string} [tenantId] The tenant id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        issueBucketErrorsRWAccessToken: async (dataDomain?: string, owner?: string, tenantId?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/accesstokenissue/rw/errors`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("APIKey")
                    : await configuration.apiKey;
                localVarHeaderParameter["APIKey"] = localVarApiKeyValue;
            }

            if (dataDomain !== undefined) {
                localVarQueryParameter['dataDomain'] = dataDomain;
            }

            if (owner !== undefined) {
                localVarQueryParameter['owner'] = owner;
            }

            if (tenantId !== undefined) {
                localVarQueryParameter['tenantId'] = tenantId;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Issues a Google Storage access token for accessing the tenant\'s folder with incidents
         * @summary issueBucketIncidentsRWAccessToken
         * @param {string} [dataDomain] The name of the data domain
         * @param {string} [owner] The email of account\&#39;s owner
         * @param {string} [tenantId] The tenant id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        issueBucketIncidentsRWAccessToken: async (dataDomain?: string, owner?: string, tenantId?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/accesstokenissue/rw/incidents`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("APIKey")
                    : await configuration.apiKey;
                localVarHeaderParameter["APIKey"] = localVarApiKeyValue;
            }

            if (dataDomain !== undefined) {
                localVarQueryParameter['dataDomain'] = dataDomain;
            }

            if (owner !== undefined) {
                localVarQueryParameter['owner'] = owner;
            }

            if (tenantId !== undefined) {
                localVarQueryParameter['tenantId'] = tenantId;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Issues a Google Storage access token for accessing the tenant\'s folder with the configuration of default checks as check patterns (patterns folder)
         * @summary issueBucketPatternsRWAccessToken
         * @param {string} [dataDomain] The name of the data domain
         * @param {string} [owner] The email of account\&#39;s owner
         * @param {string} [tenantId] The tenant id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        issueBucketPatternsRWAccessToken: async (dataDomain?: string, owner?: string, tenantId?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/accesstokenissue/rw/patterns`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("APIKey")
                    : await configuration.apiKey;
                localVarHeaderParameter["APIKey"] = localVarApiKeyValue;
            }

            if (dataDomain !== undefined) {
                localVarQueryParameter['dataDomain'] = dataDomain;
            }

            if (owner !== undefined) {
                localVarQueryParameter['owner'] = owner;
            }

            if (tenantId !== undefined) {
                localVarQueryParameter['tenantId'] = tenantId;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Issues a Google Storage access token for accessing the tenant\'s folder with custom rule definitions
         * @summary issueBucketRulesRWAccessToken
         * @param {string} [dataDomain] The name of the data domain
         * @param {string} [owner] The email of account\&#39;s owner
         * @param {string} [tenantId] The tenant id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        issueBucketRulesRWAccessToken: async (dataDomain?: string, owner?: string, tenantId?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/accesstokenissue/rw/rules`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("APIKey")
                    : await configuration.apiKey;
                localVarHeaderParameter["APIKey"] = localVarApiKeyValue;
            }

            if (dataDomain !== undefined) {
                localVarQueryParameter['dataDomain'] = dataDomain;
            }

            if (owner !== undefined) {
                localVarQueryParameter['owner'] = owner;
            }

            if (tenantId !== undefined) {
                localVarQueryParameter['tenantId'] = tenantId;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Issues a Google Storage access token for accessing the tenant\'s folder with sensor readouts
         * @summary issueBucketSensorReadoutsRWAccessToken
         * @param {string} [dataDomain] The name of the data domain
         * @param {string} [owner] The email of account\&#39;s owner
         * @param {string} [tenantId] The tenant id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        issueBucketSensorReadoutsRWAccessToken: async (dataDomain?: string, owner?: string, tenantId?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/accesstokenissue/rw/sensor_readouts`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("APIKey")
                    : await configuration.apiKey;
                localVarHeaderParameter["APIKey"] = localVarApiKeyValue;
            }

            if (dataDomain !== undefined) {
                localVarQueryParameter['dataDomain'] = dataDomain;
            }

            if (owner !== undefined) {
                localVarQueryParameter['owner'] = owner;
            }

            if (tenantId !== undefined) {
                localVarQueryParameter['tenantId'] = tenantId;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Issues a Google Storage access token for accessing the tenant\'s folder with custom sensor definitions
         * @summary issueBucketSensorsRWAccessToken
         * @param {string} [dataDomain] The name of the data domain
         * @param {string} [owner] The email of account\&#39;s owner
         * @param {string} [tenantId] The tenant id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        issueBucketSensorsRWAccessToken: async (dataDomain?: string, owner?: string, tenantId?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/accesstokenissue/rw/sensors`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("APIKey")
                    : await configuration.apiKey;
                localVarHeaderParameter["APIKey"] = localVarApiKeyValue;
            }

            if (dataDomain !== undefined) {
                localVarQueryParameter['dataDomain'] = dataDomain;
            }

            if (owner !== undefined) {
                localVarQueryParameter['owner'] = owner;
            }

            if (tenantId !== undefined) {
                localVarQueryParameter['tenantId'] = tenantId;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Issues a Google Storage access token for accessing the tenant\'s folder with settings
         * @summary issueBucketSettingsRWAccessToken
         * @param {string} [dataDomain] The name of the data domain
         * @param {string} [owner] The email of account\&#39;s owner
         * @param {string} [tenantId] The tenant id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        issueBucketSettingsRWAccessToken: async (dataDomain?: string, owner?: string, tenantId?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/accesstokenissue/rw/settings`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("APIKey")
                    : await configuration.apiKey;
                localVarHeaderParameter["APIKey"] = localVarApiKeyValue;
            }

            if (dataDomain !== undefined) {
                localVarQueryParameter['dataDomain'] = dataDomain;
            }

            if (owner !== undefined) {
                localVarQueryParameter['owner'] = owner;
            }

            if (tenantId !== undefined) {
                localVarQueryParameter['tenantId'] = tenantId;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Issues a Google Storage access token for accessing the tenant\'s folder with sources and the data quality check configuration
         * @summary issueBucketSourcesRWAccessToken
         * @param {string} [dataDomain] The name of the data domain
         * @param {string} [owner] The email of account\&#39;s owner
         * @param {string} [tenantId] The tenant id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        issueBucketSourcesRWAccessToken: async (dataDomain?: string, owner?: string, tenantId?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/accesstokenissue/rw/sources`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("APIKey")
                    : await configuration.apiKey;
                localVarHeaderParameter["APIKey"] = localVarApiKeyValue;
            }

            if (dataDomain !== undefined) {
                localVarQueryParameter['dataDomain'] = dataDomain;
            }

            if (owner !== undefined) {
                localVarQueryParameter['owner'] = owner;
            }

            if (tenantId !== undefined) {
                localVarQueryParameter['tenantId'] = tenantId;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Issues a Google Storage access token for accessing the tenant\'s folder with statistics
         * @summary issueBucketStatisticsRWAccessToken
         * @param {string} [dataDomain] The name of the data domain
         * @param {string} [owner] The email of account\&#39;s owner
         * @param {string} [tenantId] The tenant id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        issueBucketStatisticsRWAccessToken: async (dataDomain?: string, owner?: string, tenantId?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/accesstokenissue/rw/statistics`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("APIKey")
                    : await configuration.apiKey;
                localVarHeaderParameter["APIKey"] = localVarApiKeyValue;
            }

            if (dataDomain !== undefined) {
                localVarQueryParameter['dataDomain'] = dataDomain;
            }

            if (owner !== undefined) {
                localVarQueryParameter['owner'] = owner;
            }

            if (tenantId !== undefined) {
                localVarQueryParameter['tenantId'] = tenantId;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Issues a read-only Google access token used by the DQO Looker Studio Community Connector to query the tenant\'s data quality data warehouse.
         * @summary issueTenantDataROQueryAccessToken
         * @param {string} [owner] The email of account\&#39;s owner
         * @param {string} [tenantId] The tenant id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        issueTenantDataROQueryAccessToken: async (owner?: string, tenantId?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/accesstokenissue/ro/query`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("APIKey")
                    : await configuration.apiKey;
                localVarHeaderParameter["APIKey"] = localVarApiKeyValue;
            }

            if (owner !== undefined) {
                localVarQueryParameter['owner'] = owner;
            }

            if (tenantId !== undefined) {
                localVarQueryParameter['tenantId'] = tenantId;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AccessTokenIssueApi - functional programming interface
 * @export
 */
export const AccessTokenIssueApiFp = function(configuration?: Configuration) {
    return {
        /**
         * Issues a Google Storage access token for accessing the tenant\'s folder with rule results
         * @summary issueBucketCheckResultsRWAccessToken
         * @param {string} [dataDomain] The name of the data domain
         * @param {string} [owner] The email of account\&#39;s owner
         * @param {string} [tenantId] The tenant id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async issueBucketCheckResultsRWAccessToken(dataDomain?: string, owner?: string, tenantId?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TenantAccessTokenModel>> {
            const localVarAxiosArgs = await AccessTokenIssueApiAxiosParamCreator(configuration).issueBucketCheckResultsRWAccessToken(dataDomain, owner, tenantId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Issues a Google Storage access token for accessing the tenant\'s folder with custom check definitions
         * @summary issueBucketChecksRWAccessToken
         * @param {string} [dataDomain] The name of the data domain
         * @param {string} [owner] The email of account\&#39;s owner
         * @param {string} [tenantId] The tenant id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async issueBucketChecksRWAccessToken(dataDomain?: string, owner?: string, tenantId?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TenantAccessTokenModel>> {
            const localVarAxiosArgs = await AccessTokenIssueApiAxiosParamCreator(configuration).issueBucketChecksRWAccessToken(dataDomain, owner, tenantId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Issues a Google Storage access token for accessing the tenant\'s folder with credentials (.credentials folder)
         * @summary issueBucketCredentialsRWAccessToken
         * @param {string} [dataDomain] The name of the data domain
         * @param {string} [owner] The email of account\&#39;s owner
         * @param {string} [tenantId] The tenant id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async issueBucketCredentialsRWAccessToken(dataDomain?: string, owner?: string, tenantId?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TenantAccessTokenModel>> {
            const localVarAxiosArgs = await AccessTokenIssueApiAxiosParamCreator(configuration).issueBucketCredentialsRWAccessToken(dataDomain, owner, tenantId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Issues a Google Storage access token for accessing the tenant\'s folder with custom term dictionaries (dictionaries folder)
         * @summary issueBucketDictionariesRWAccessToken
         * @param {string} [dataDomain] The name of the data domain
         * @param {string} [owner] The email of account\&#39;s owner
         * @param {string} [tenantId] The tenant id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async issueBucketDictionariesRWAccessToken(dataDomain?: string, owner?: string, tenantId?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TenantAccessTokenModel>> {
            const localVarAxiosArgs = await AccessTokenIssueApiAxiosParamCreator(configuration).issueBucketDictionariesRWAccessToken(dataDomain, owner, tenantId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Issues a Google Storage access token for accessing the tenant\'s folder with error samples
         * @summary issueBucketErrorSamplesRWAccessToken
         * @param {string} [dataDomain] The name of the data domain
         * @param {string} [owner] The email of account\&#39;s owner
         * @param {string} [tenantId] The tenant id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async issueBucketErrorSamplesRWAccessToken(dataDomain?: string, owner?: string, tenantId?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TenantAccessTokenModel>> {
            const localVarAxiosArgs = await AccessTokenIssueApiAxiosParamCreator(configuration).issueBucketErrorSamplesRWAccessToken(dataDomain, owner, tenantId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Issues a Google Storage access token for accessing the tenant\'s folder with errors
         * @summary issueBucketErrorsRWAccessToken
         * @param {string} [dataDomain] The name of the data domain
         * @param {string} [owner] The email of account\&#39;s owner
         * @param {string} [tenantId] The tenant id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async issueBucketErrorsRWAccessToken(dataDomain?: string, owner?: string, tenantId?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TenantAccessTokenModel>> {
            const localVarAxiosArgs = await AccessTokenIssueApiAxiosParamCreator(configuration).issueBucketErrorsRWAccessToken(dataDomain, owner, tenantId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Issues a Google Storage access token for accessing the tenant\'s folder with incidents
         * @summary issueBucketIncidentsRWAccessToken
         * @param {string} [dataDomain] The name of the data domain
         * @param {string} [owner] The email of account\&#39;s owner
         * @param {string} [tenantId] The tenant id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async issueBucketIncidentsRWAccessToken(dataDomain?: string, owner?: string, tenantId?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TenantAccessTokenModel>> {
            const localVarAxiosArgs = await AccessTokenIssueApiAxiosParamCreator(configuration).issueBucketIncidentsRWAccessToken(dataDomain, owner, tenantId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Issues a Google Storage access token for accessing the tenant\'s folder with the configuration of default checks as check patterns (patterns folder)
         * @summary issueBucketPatternsRWAccessToken
         * @param {string} [dataDomain] The name of the data domain
         * @param {string} [owner] The email of account\&#39;s owner
         * @param {string} [tenantId] The tenant id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async issueBucketPatternsRWAccessToken(dataDomain?: string, owner?: string, tenantId?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TenantAccessTokenModel>> {
            const localVarAxiosArgs = await AccessTokenIssueApiAxiosParamCreator(configuration).issueBucketPatternsRWAccessToken(dataDomain, owner, tenantId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Issues a Google Storage access token for accessing the tenant\'s folder with custom rule definitions
         * @summary issueBucketRulesRWAccessToken
         * @param {string} [dataDomain] The name of the data domain
         * @param {string} [owner] The email of account\&#39;s owner
         * @param {string} [tenantId] The tenant id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async issueBucketRulesRWAccessToken(dataDomain?: string, owner?: string, tenantId?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TenantAccessTokenModel>> {
            const localVarAxiosArgs = await AccessTokenIssueApiAxiosParamCreator(configuration).issueBucketRulesRWAccessToken(dataDomain, owner, tenantId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Issues a Google Storage access token for accessing the tenant\'s folder with sensor readouts
         * @summary issueBucketSensorReadoutsRWAccessToken
         * @param {string} [dataDomain] The name of the data domain
         * @param {string} [owner] The email of account\&#39;s owner
         * @param {string} [tenantId] The tenant id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async issueBucketSensorReadoutsRWAccessToken(dataDomain?: string, owner?: string, tenantId?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TenantAccessTokenModel>> {
            const localVarAxiosArgs = await AccessTokenIssueApiAxiosParamCreator(configuration).issueBucketSensorReadoutsRWAccessToken(dataDomain, owner, tenantId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Issues a Google Storage access token for accessing the tenant\'s folder with custom sensor definitions
         * @summary issueBucketSensorsRWAccessToken
         * @param {string} [dataDomain] The name of the data domain
         * @param {string} [owner] The email of account\&#39;s owner
         * @param {string} [tenantId] The tenant id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async issueBucketSensorsRWAccessToken(dataDomain?: string, owner?: string, tenantId?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TenantAccessTokenModel>> {
            const localVarAxiosArgs = await AccessTokenIssueApiAxiosParamCreator(configuration).issueBucketSensorsRWAccessToken(dataDomain, owner, tenantId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Issues a Google Storage access token for accessing the tenant\'s folder with settings
         * @summary issueBucketSettingsRWAccessToken
         * @param {string} [dataDomain] The name of the data domain
         * @param {string} [owner] The email of account\&#39;s owner
         * @param {string} [tenantId] The tenant id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async issueBucketSettingsRWAccessToken(dataDomain?: string, owner?: string, tenantId?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TenantAccessTokenModel>> {
            const localVarAxiosArgs = await AccessTokenIssueApiAxiosParamCreator(configuration).issueBucketSettingsRWAccessToken(dataDomain, owner, tenantId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Issues a Google Storage access token for accessing the tenant\'s folder with sources and the data quality check configuration
         * @summary issueBucketSourcesRWAccessToken
         * @param {string} [dataDomain] The name of the data domain
         * @param {string} [owner] The email of account\&#39;s owner
         * @param {string} [tenantId] The tenant id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async issueBucketSourcesRWAccessToken(dataDomain?: string, owner?: string, tenantId?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TenantAccessTokenModel>> {
            const localVarAxiosArgs = await AccessTokenIssueApiAxiosParamCreator(configuration).issueBucketSourcesRWAccessToken(dataDomain, owner, tenantId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Issues a Google Storage access token for accessing the tenant\'s folder with statistics
         * @summary issueBucketStatisticsRWAccessToken
         * @param {string} [dataDomain] The name of the data domain
         * @param {string} [owner] The email of account\&#39;s owner
         * @param {string} [tenantId] The tenant id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async issueBucketStatisticsRWAccessToken(dataDomain?: string, owner?: string, tenantId?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TenantAccessTokenModel>> {
            const localVarAxiosArgs = await AccessTokenIssueApiAxiosParamCreator(configuration).issueBucketStatisticsRWAccessToken(dataDomain, owner, tenantId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Issues a read-only Google access token used by the DQO Looker Studio Community Connector to query the tenant\'s data quality data warehouse.
         * @summary issueTenantDataROQueryAccessToken
         * @param {string} [owner] The email of account\&#39;s owner
         * @param {string} [tenantId] The tenant id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async issueTenantDataROQueryAccessToken(owner?: string, tenantId?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TenantQueryAccessTokenModel>> {
            const localVarAxiosArgs = await AccessTokenIssueApiAxiosParamCreator(configuration).issueTenantDataROQueryAccessToken(owner, tenantId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * AccessTokenIssueApi - factory interface
 * @export
 */
export const AccessTokenIssueApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * Issues a Google Storage access token for accessing the tenant\'s folder with rule results
         * @summary issueBucketCheckResultsRWAccessToken
         * @param {string} [dataDomain] The name of the data domain
         * @param {string} [owner] The email of account\&#39;s owner
         * @param {string} [tenantId] The tenant id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        issueBucketCheckResultsRWAccessToken(dataDomain?: string, owner?: string, tenantId?: string, options?: any): AxiosPromise<TenantAccessTokenModel> {
            return AccessTokenIssueApiFp(configuration).issueBucketCheckResultsRWAccessToken(dataDomain, owner, tenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * Issues a Google Storage access token for accessing the tenant\'s folder with custom check definitions
         * @summary issueBucketChecksRWAccessToken
         * @param {string} [dataDomain] The name of the data domain
         * @param {string} [owner] The email of account\&#39;s owner
         * @param {string} [tenantId] The tenant id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        issueBucketChecksRWAccessToken(dataDomain?: string, owner?: string, tenantId?: string, options?: any): AxiosPromise<TenantAccessTokenModel> {
            return AccessTokenIssueApiFp(configuration).issueBucketChecksRWAccessToken(dataDomain, owner, tenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * Issues a Google Storage access token for accessing the tenant\'s folder with credentials (.credentials folder)
         * @summary issueBucketCredentialsRWAccessToken
         * @param {string} [dataDomain] The name of the data domain
         * @param {string} [owner] The email of account\&#39;s owner
         * @param {string} [tenantId] The tenant id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        issueBucketCredentialsRWAccessToken(dataDomain?: string, owner?: string, tenantId?: string, options?: any): AxiosPromise<TenantAccessTokenModel> {
            return AccessTokenIssueApiFp(configuration).issueBucketCredentialsRWAccessToken(dataDomain, owner, tenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * Issues a Google Storage access token for accessing the tenant\'s folder with custom term dictionaries (dictionaries folder)
         * @summary issueBucketDictionariesRWAccessToken
         * @param {string} [dataDomain] The name of the data domain
         * @param {string} [owner] The email of account\&#39;s owner
         * @param {string} [tenantId] The tenant id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        issueBucketDictionariesRWAccessToken(dataDomain?: string, owner?: string, tenantId?: string, options?: any): AxiosPromise<TenantAccessTokenModel> {
            return AccessTokenIssueApiFp(configuration).issueBucketDictionariesRWAccessToken(dataDomain, owner, tenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * Issues a Google Storage access token for accessing the tenant\'s folder with error samples
         * @summary issueBucketErrorSamplesRWAccessToken
         * @param {string} [dataDomain] The name of the data domain
         * @param {string} [owner] The email of account\&#39;s owner
         * @param {string} [tenantId] The tenant id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        issueBucketErrorSamplesRWAccessToken(dataDomain?: string, owner?: string, tenantId?: string, options?: any): AxiosPromise<TenantAccessTokenModel> {
            return AccessTokenIssueApiFp(configuration).issueBucketErrorSamplesRWAccessToken(dataDomain, owner, tenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * Issues a Google Storage access token for accessing the tenant\'s folder with errors
         * @summary issueBucketErrorsRWAccessToken
         * @param {string} [dataDomain] The name of the data domain
         * @param {string} [owner] The email of account\&#39;s owner
         * @param {string} [tenantId] The tenant id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        issueBucketErrorsRWAccessToken(dataDomain?: string, owner?: string, tenantId?: string, options?: any): AxiosPromise<TenantAccessTokenModel> {
            return AccessTokenIssueApiFp(configuration).issueBucketErrorsRWAccessToken(dataDomain, owner, tenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * Issues a Google Storage access token for accessing the tenant\'s folder with incidents
         * @summary issueBucketIncidentsRWAccessToken
         * @param {string} [dataDomain] The name of the data domain
         * @param {string} [owner] The email of account\&#39;s owner
         * @param {string} [tenantId] The tenant id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        issueBucketIncidentsRWAccessToken(dataDomain?: string, owner?: string, tenantId?: string, options?: any): AxiosPromise<TenantAccessTokenModel> {
            return AccessTokenIssueApiFp(configuration).issueBucketIncidentsRWAccessToken(dataDomain, owner, tenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * Issues a Google Storage access token for accessing the tenant\'s folder with the configuration of default checks as check patterns (patterns folder)
         * @summary issueBucketPatternsRWAccessToken
         * @param {string} [dataDomain] The name of the data domain
         * @param {string} [owner] The email of account\&#39;s owner
         * @param {string} [tenantId] The tenant id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        issueBucketPatternsRWAccessToken(dataDomain?: string, owner?: string, tenantId?: string, options?: any): AxiosPromise<TenantAccessTokenModel> {
            return AccessTokenIssueApiFp(configuration).issueBucketPatternsRWAccessToken(dataDomain, owner, tenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * Issues a Google Storage access token for accessing the tenant\'s folder with custom rule definitions
         * @summary issueBucketRulesRWAccessToken
         * @param {string} [dataDomain] The name of the data domain
         * @param {string} [owner] The email of account\&#39;s owner
         * @param {string} [tenantId] The tenant id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        issueBucketRulesRWAccessToken(dataDomain?: string, owner?: string, tenantId?: string, options?: any): AxiosPromise<TenantAccessTokenModel> {
            return AccessTokenIssueApiFp(configuration).issueBucketRulesRWAccessToken(dataDomain, owner, tenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * Issues a Google Storage access token for accessing the tenant\'s folder with sensor readouts
         * @summary issueBucketSensorReadoutsRWAccessToken
         * @param {string} [dataDomain] The name of the data domain
         * @param {string} [owner] The email of account\&#39;s owner
         * @param {string} [tenantId] The tenant id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        issueBucketSensorReadoutsRWAccessToken(dataDomain?: string, owner?: string, tenantId?: string, options?: any): AxiosPromise<TenantAccessTokenModel> {
            return AccessTokenIssueApiFp(configuration).issueBucketSensorReadoutsRWAccessToken(dataDomain, owner, tenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * Issues a Google Storage access token for accessing the tenant\'s folder with custom sensor definitions
         * @summary issueBucketSensorsRWAccessToken
         * @param {string} [dataDomain] The name of the data domain
         * @param {string} [owner] The email of account\&#39;s owner
         * @param {string} [tenantId] The tenant id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        issueBucketSensorsRWAccessToken(dataDomain?: string, owner?: string, tenantId?: string, options?: any): AxiosPromise<TenantAccessTokenModel> {
            return AccessTokenIssueApiFp(configuration).issueBucketSensorsRWAccessToken(dataDomain, owner, tenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * Issues a Google Storage access token for accessing the tenant\'s folder with settings
         * @summary issueBucketSettingsRWAccessToken
         * @param {string} [dataDomain] The name of the data domain
         * @param {string} [owner] The email of account\&#39;s owner
         * @param {string} [tenantId] The tenant id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        issueBucketSettingsRWAccessToken(dataDomain?: string, owner?: string, tenantId?: string, options?: any): AxiosPromise<TenantAccessTokenModel> {
            return AccessTokenIssueApiFp(configuration).issueBucketSettingsRWAccessToken(dataDomain, owner, tenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * Issues a Google Storage access token for accessing the tenant\'s folder with sources and the data quality check configuration
         * @summary issueBucketSourcesRWAccessToken
         * @param {string} [dataDomain] The name of the data domain
         * @param {string} [owner] The email of account\&#39;s owner
         * @param {string} [tenantId] The tenant id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        issueBucketSourcesRWAccessToken(dataDomain?: string, owner?: string, tenantId?: string, options?: any): AxiosPromise<TenantAccessTokenModel> {
            return AccessTokenIssueApiFp(configuration).issueBucketSourcesRWAccessToken(dataDomain, owner, tenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * Issues a Google Storage access token for accessing the tenant\'s folder with statistics
         * @summary issueBucketStatisticsRWAccessToken
         * @param {string} [dataDomain] The name of the data domain
         * @param {string} [owner] The email of account\&#39;s owner
         * @param {string} [tenantId] The tenant id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        issueBucketStatisticsRWAccessToken(dataDomain?: string, owner?: string, tenantId?: string, options?: any): AxiosPromise<TenantAccessTokenModel> {
            return AccessTokenIssueApiFp(configuration).issueBucketStatisticsRWAccessToken(dataDomain, owner, tenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * Issues a read-only Google access token used by the DQO Looker Studio Community Connector to query the tenant\'s data quality data warehouse.
         * @summary issueTenantDataROQueryAccessToken
         * @param {string} [owner] The email of account\&#39;s owner
         * @param {string} [tenantId] The tenant id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        issueTenantDataROQueryAccessToken(owner?: string, tenantId?: string, options?: any): AxiosPromise<TenantQueryAccessTokenModel> {
            return AccessTokenIssueApiFp(configuration).issueTenantDataROQueryAccessToken(owner, tenantId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AccessTokenIssueApi - interface
 * @export
 * @interface AccessTokenIssueApi
 */
export interface AccessTokenIssueApiInterface {
    /**
     * Issues a Google Storage access token for accessing the tenant\'s folder with rule results
     * @summary issueBucketCheckResultsRWAccessToken
     * @param {string} [dataDomain] The name of the data domain
     * @param {string} [owner] The email of account\&#39;s owner
     * @param {string} [tenantId] The tenant id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccessTokenIssueApiInterface
     */
    issueBucketCheckResultsRWAccessToken(dataDomain?: string, owner?: string, tenantId?: string, options?: any): AxiosPromise<TenantAccessTokenModel>;

    /**
     * Issues a Google Storage access token for accessing the tenant\'s folder with custom check definitions
     * @summary issueBucketChecksRWAccessToken
     * @param {string} [dataDomain] The name of the data domain
     * @param {string} [owner] The email of account\&#39;s owner
     * @param {string} [tenantId] The tenant id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccessTokenIssueApiInterface
     */
    issueBucketChecksRWAccessToken(dataDomain?: string, owner?: string, tenantId?: string, options?: any): AxiosPromise<TenantAccessTokenModel>;

    /**
     * Issues a Google Storage access token for accessing the tenant\'s folder with credentials (.credentials folder)
     * @summary issueBucketCredentialsRWAccessToken
     * @param {string} [dataDomain] The name of the data domain
     * @param {string} [owner] The email of account\&#39;s owner
     * @param {string} [tenantId] The tenant id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccessTokenIssueApiInterface
     */
    issueBucketCredentialsRWAccessToken(dataDomain?: string, owner?: string, tenantId?: string, options?: any): AxiosPromise<TenantAccessTokenModel>;

    /**
     * Issues a Google Storage access token for accessing the tenant\'s folder with custom term dictionaries (dictionaries folder)
     * @summary issueBucketDictionariesRWAccessToken
     * @param {string} [dataDomain] The name of the data domain
     * @param {string} [owner] The email of account\&#39;s owner
     * @param {string} [tenantId] The tenant id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccessTokenIssueApiInterface
     */
    issueBucketDictionariesRWAccessToken(dataDomain?: string, owner?: string, tenantId?: string, options?: any): AxiosPromise<TenantAccessTokenModel>;

    /**
     * Issues a Google Storage access token for accessing the tenant\'s folder with error samples
     * @summary issueBucketErrorSamplesRWAccessToken
     * @param {string} [dataDomain] The name of the data domain
     * @param {string} [owner] The email of account\&#39;s owner
     * @param {string} [tenantId] The tenant id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccessTokenIssueApiInterface
     */
    issueBucketErrorSamplesRWAccessToken(dataDomain?: string, owner?: string, tenantId?: string, options?: any): AxiosPromise<TenantAccessTokenModel>;

    /**
     * Issues a Google Storage access token for accessing the tenant\'s folder with errors
     * @summary issueBucketErrorsRWAccessToken
     * @param {string} [dataDomain] The name of the data domain
     * @param {string} [owner] The email of account\&#39;s owner
     * @param {string} [tenantId] The tenant id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccessTokenIssueApiInterface
     */
    issueBucketErrorsRWAccessToken(dataDomain?: string, owner?: string, tenantId?: string, options?: any): AxiosPromise<TenantAccessTokenModel>;

    /**
     * Issues a Google Storage access token for accessing the tenant\'s folder with incidents
     * @summary issueBucketIncidentsRWAccessToken
     * @param {string} [dataDomain] The name of the data domain
     * @param {string} [owner] The email of account\&#39;s owner
     * @param {string} [tenantId] The tenant id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccessTokenIssueApiInterface
     */
    issueBucketIncidentsRWAccessToken(dataDomain?: string, owner?: string, tenantId?: string, options?: any): AxiosPromise<TenantAccessTokenModel>;

    /**
     * Issues a Google Storage access token for accessing the tenant\'s folder with the configuration of default checks as check patterns (patterns folder)
     * @summary issueBucketPatternsRWAccessToken
     * @param {string} [dataDomain] The name of the data domain
     * @param {string} [owner] The email of account\&#39;s owner
     * @param {string} [tenantId] The tenant id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccessTokenIssueApiInterface
     */
    issueBucketPatternsRWAccessToken(dataDomain?: string, owner?: string, tenantId?: string, options?: any): AxiosPromise<TenantAccessTokenModel>;

    /**
     * Issues a Google Storage access token for accessing the tenant\'s folder with custom rule definitions
     * @summary issueBucketRulesRWAccessToken
     * @param {string} [dataDomain] The name of the data domain
     * @param {string} [owner] The email of account\&#39;s owner
     * @param {string} [tenantId] The tenant id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccessTokenIssueApiInterface
     */
    issueBucketRulesRWAccessToken(dataDomain?: string, owner?: string, tenantId?: string, options?: any): AxiosPromise<TenantAccessTokenModel>;

    /**
     * Issues a Google Storage access token for accessing the tenant\'s folder with sensor readouts
     * @summary issueBucketSensorReadoutsRWAccessToken
     * @param {string} [dataDomain] The name of the data domain
     * @param {string} [owner] The email of account\&#39;s owner
     * @param {string} [tenantId] The tenant id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccessTokenIssueApiInterface
     */
    issueBucketSensorReadoutsRWAccessToken(dataDomain?: string, owner?: string, tenantId?: string, options?: any): AxiosPromise<TenantAccessTokenModel>;

    /**
     * Issues a Google Storage access token for accessing the tenant\'s folder with custom sensor definitions
     * @summary issueBucketSensorsRWAccessToken
     * @param {string} [dataDomain] The name of the data domain
     * @param {string} [owner] The email of account\&#39;s owner
     * @param {string} [tenantId] The tenant id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccessTokenIssueApiInterface
     */
    issueBucketSensorsRWAccessToken(dataDomain?: string, owner?: string, tenantId?: string, options?: any): AxiosPromise<TenantAccessTokenModel>;

    /**
     * Issues a Google Storage access token for accessing the tenant\'s folder with settings
     * @summary issueBucketSettingsRWAccessToken
     * @param {string} [dataDomain] The name of the data domain
     * @param {string} [owner] The email of account\&#39;s owner
     * @param {string} [tenantId] The tenant id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccessTokenIssueApiInterface
     */
    issueBucketSettingsRWAccessToken(dataDomain?: string, owner?: string, tenantId?: string, options?: any): AxiosPromise<TenantAccessTokenModel>;

    /**
     * Issues a Google Storage access token for accessing the tenant\'s folder with sources and the data quality check configuration
     * @summary issueBucketSourcesRWAccessToken
     * @param {string} [dataDomain] The name of the data domain
     * @param {string} [owner] The email of account\&#39;s owner
     * @param {string} [tenantId] The tenant id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccessTokenIssueApiInterface
     */
    issueBucketSourcesRWAccessToken(dataDomain?: string, owner?: string, tenantId?: string, options?: any): AxiosPromise<TenantAccessTokenModel>;

    /**
     * Issues a Google Storage access token for accessing the tenant\'s folder with statistics
     * @summary issueBucketStatisticsRWAccessToken
     * @param {string} [dataDomain] The name of the data domain
     * @param {string} [owner] The email of account\&#39;s owner
     * @param {string} [tenantId] The tenant id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccessTokenIssueApiInterface
     */
    issueBucketStatisticsRWAccessToken(dataDomain?: string, owner?: string, tenantId?: string, options?: any): AxiosPromise<TenantAccessTokenModel>;

    /**
     * Issues a read-only Google access token used by the DQO Looker Studio Community Connector to query the tenant\'s data quality data warehouse.
     * @summary issueTenantDataROQueryAccessToken
     * @param {string} [owner] The email of account\&#39;s owner
     * @param {string} [tenantId] The tenant id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccessTokenIssueApiInterface
     */
    issueTenantDataROQueryAccessToken(owner?: string, tenantId?: string, options?: any): AxiosPromise<TenantQueryAccessTokenModel>;

}

/**
 * AccessTokenIssueApi - object-oriented interface
 * @export
 * @class AccessTokenIssueApi
 * @extends {BaseAPI}
 */
export class AccessTokenIssueApi extends BaseAPI implements AccessTokenIssueApiInterface {
    /**
     * Issues a Google Storage access token for accessing the tenant\'s folder with rule results
     * @summary issueBucketCheckResultsRWAccessToken
     * @param {string} [dataDomain] The name of the data domain
     * @param {string} [owner] The email of account\&#39;s owner
     * @param {string} [tenantId] The tenant id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccessTokenIssueApi
     */
    public issueBucketCheckResultsRWAccessToken(dataDomain?: string, owner?: string, tenantId?: string, options?: any) {
        return AccessTokenIssueApiFp(this.configuration).issueBucketCheckResultsRWAccessToken(dataDomain, owner, tenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Issues a Google Storage access token for accessing the tenant\'s folder with custom check definitions
     * @summary issueBucketChecksRWAccessToken
     * @param {string} [dataDomain] The name of the data domain
     * @param {string} [owner] The email of account\&#39;s owner
     * @param {string} [tenantId] The tenant id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccessTokenIssueApi
     */
    public issueBucketChecksRWAccessToken(dataDomain?: string, owner?: string, tenantId?: string, options?: any) {
        return AccessTokenIssueApiFp(this.configuration).issueBucketChecksRWAccessToken(dataDomain, owner, tenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Issues a Google Storage access token for accessing the tenant\'s folder with credentials (.credentials folder)
     * @summary issueBucketCredentialsRWAccessToken
     * @param {string} [dataDomain] The name of the data domain
     * @param {string} [owner] The email of account\&#39;s owner
     * @param {string} [tenantId] The tenant id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccessTokenIssueApi
     */
    public issueBucketCredentialsRWAccessToken(dataDomain?: string, owner?: string, tenantId?: string, options?: any) {
        return AccessTokenIssueApiFp(this.configuration).issueBucketCredentialsRWAccessToken(dataDomain, owner, tenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Issues a Google Storage access token for accessing the tenant\'s folder with custom term dictionaries (dictionaries folder)
     * @summary issueBucketDictionariesRWAccessToken
     * @param {string} [dataDomain] The name of the data domain
     * @param {string} [owner] The email of account\&#39;s owner
     * @param {string} [tenantId] The tenant id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccessTokenIssueApi
     */
    public issueBucketDictionariesRWAccessToken(dataDomain?: string, owner?: string, tenantId?: string, options?: any) {
        return AccessTokenIssueApiFp(this.configuration).issueBucketDictionariesRWAccessToken(dataDomain, owner, tenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Issues a Google Storage access token for accessing the tenant\'s folder with error samples
     * @summary issueBucketErrorSamplesRWAccessToken
     * @param {string} [dataDomain] The name of the data domain
     * @param {string} [owner] The email of account\&#39;s owner
     * @param {string} [tenantId] The tenant id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccessTokenIssueApi
     */
    public issueBucketErrorSamplesRWAccessToken(dataDomain?: string, owner?: string, tenantId?: string, options?: any) {
        return AccessTokenIssueApiFp(this.configuration).issueBucketErrorSamplesRWAccessToken(dataDomain, owner, tenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Issues a Google Storage access token for accessing the tenant\'s folder with errors
     * @summary issueBucketErrorsRWAccessToken
     * @param {string} [dataDomain] The name of the data domain
     * @param {string} [owner] The email of account\&#39;s owner
     * @param {string} [tenantId] The tenant id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccessTokenIssueApi
     */
    public issueBucketErrorsRWAccessToken(dataDomain?: string, owner?: string, tenantId?: string, options?: any) {
        return AccessTokenIssueApiFp(this.configuration).issueBucketErrorsRWAccessToken(dataDomain, owner, tenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Issues a Google Storage access token for accessing the tenant\'s folder with incidents
     * @summary issueBucketIncidentsRWAccessToken
     * @param {string} [dataDomain] The name of the data domain
     * @param {string} [owner] The email of account\&#39;s owner
     * @param {string} [tenantId] The tenant id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccessTokenIssueApi
     */
    public issueBucketIncidentsRWAccessToken(dataDomain?: string, owner?: string, tenantId?: string, options?: any) {
        return AccessTokenIssueApiFp(this.configuration).issueBucketIncidentsRWAccessToken(dataDomain, owner, tenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Issues a Google Storage access token for accessing the tenant\'s folder with the configuration of default checks as check patterns (patterns folder)
     * @summary issueBucketPatternsRWAccessToken
     * @param {string} [dataDomain] The name of the data domain
     * @param {string} [owner] The email of account\&#39;s owner
     * @param {string} [tenantId] The tenant id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccessTokenIssueApi
     */
    public issueBucketPatternsRWAccessToken(dataDomain?: string, owner?: string, tenantId?: string, options?: any) {
        return AccessTokenIssueApiFp(this.configuration).issueBucketPatternsRWAccessToken(dataDomain, owner, tenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Issues a Google Storage access token for accessing the tenant\'s folder with custom rule definitions
     * @summary issueBucketRulesRWAccessToken
     * @param {string} [dataDomain] The name of the data domain
     * @param {string} [owner] The email of account\&#39;s owner
     * @param {string} [tenantId] The tenant id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccessTokenIssueApi
     */
    public issueBucketRulesRWAccessToken(dataDomain?: string, owner?: string, tenantId?: string, options?: any) {
        return AccessTokenIssueApiFp(this.configuration).issueBucketRulesRWAccessToken(dataDomain, owner, tenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Issues a Google Storage access token for accessing the tenant\'s folder with sensor readouts
     * @summary issueBucketSensorReadoutsRWAccessToken
     * @param {string} [dataDomain] The name of the data domain
     * @param {string} [owner] The email of account\&#39;s owner
     * @param {string} [tenantId] The tenant id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccessTokenIssueApi
     */
    public issueBucketSensorReadoutsRWAccessToken(dataDomain?: string, owner?: string, tenantId?: string, options?: any) {
        return AccessTokenIssueApiFp(this.configuration).issueBucketSensorReadoutsRWAccessToken(dataDomain, owner, tenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Issues a Google Storage access token for accessing the tenant\'s folder with custom sensor definitions
     * @summary issueBucketSensorsRWAccessToken
     * @param {string} [dataDomain] The name of the data domain
     * @param {string} [owner] The email of account\&#39;s owner
     * @param {string} [tenantId] The tenant id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccessTokenIssueApi
     */
    public issueBucketSensorsRWAccessToken(dataDomain?: string, owner?: string, tenantId?: string, options?: any) {
        return AccessTokenIssueApiFp(this.configuration).issueBucketSensorsRWAccessToken(dataDomain, owner, tenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Issues a Google Storage access token for accessing the tenant\'s folder with settings
     * @summary issueBucketSettingsRWAccessToken
     * @param {string} [dataDomain] The name of the data domain
     * @param {string} [owner] The email of account\&#39;s owner
     * @param {string} [tenantId] The tenant id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccessTokenIssueApi
     */
    public issueBucketSettingsRWAccessToken(dataDomain?: string, owner?: string, tenantId?: string, options?: any) {
        return AccessTokenIssueApiFp(this.configuration).issueBucketSettingsRWAccessToken(dataDomain, owner, tenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Issues a Google Storage access token for accessing the tenant\'s folder with sources and the data quality check configuration
     * @summary issueBucketSourcesRWAccessToken
     * @param {string} [dataDomain] The name of the data domain
     * @param {string} [owner] The email of account\&#39;s owner
     * @param {string} [tenantId] The tenant id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccessTokenIssueApi
     */
    public issueBucketSourcesRWAccessToken(dataDomain?: string, owner?: string, tenantId?: string, options?: any) {
        return AccessTokenIssueApiFp(this.configuration).issueBucketSourcesRWAccessToken(dataDomain, owner, tenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Issues a Google Storage access token for accessing the tenant\'s folder with statistics
     * @summary issueBucketStatisticsRWAccessToken
     * @param {string} [dataDomain] The name of the data domain
     * @param {string} [owner] The email of account\&#39;s owner
     * @param {string} [tenantId] The tenant id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccessTokenIssueApi
     */
    public issueBucketStatisticsRWAccessToken(dataDomain?: string, owner?: string, tenantId?: string, options?: any) {
        return AccessTokenIssueApiFp(this.configuration).issueBucketStatisticsRWAccessToken(dataDomain, owner, tenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Issues a read-only Google access token used by the DQO Looker Studio Community Connector to query the tenant\'s data quality data warehouse.
     * @summary issueTenantDataROQueryAccessToken
     * @param {string} [owner] The email of account\&#39;s owner
     * @param {string} [tenantId] The tenant id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccessTokenIssueApi
     */
    public issueTenantDataROQueryAccessToken(owner?: string, tenantId?: string, options?: any) {
        return AccessTokenIssueApiFp(this.configuration).issueTenantDataROQueryAccessToken(owner, tenantId, options).then((request) => request(this.axios, this.basePath));
    }

}


/**
 * AccountUsersApi - axios parameter creator
 * @export
 */
export const AccountUsersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Changes the password of the given user. The user can change his own password.
         * @summary changeAccountUserPassword
         * @param {string} email User\&#39;s email
         * @param {string} [owner] The email of account\&#39;s owner
         * @param {string} [tenantId] The tenant id
         * @param {string} [body] New password
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeAccountUserPassword: async (email: string, owner?: string, tenantId?: string, body?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'email' is not null or undefined
            if (email === null || email === undefined) {
                throw new RequiredError('email','Required parameter email was null or undefined when calling changeAccountUserPassword.');
            }
            const localVarPath = `/api/v1/users/{email}/password`
                .replace(`{${"email"}}`, encodeURIComponent(String(email)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("APIKey")
                    : await configuration.apiKey;
                localVarHeaderParameter["APIKey"] = localVarApiKeyValue;
            }

            if (owner !== undefined) {
                localVarQueryParameter['owner'] = owner;
            }

            if (tenantId !== undefined) {
                localVarQueryParameter['tenantId'] = tenantId;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Adds a new user to a multi-user account. Only accounts on a TEAM or ENTERPRISE license can add new users.
         * @summary createAccountUser
         * @param {string} [owner] The email of account\&#39;s owner
         * @param {string} [tenantId] The tenant id
         * @param {DqoUserModel} [body] New user model
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAccountUser: async (owner?: string, tenantId?: string, body?: DqoUserModel, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/users`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("APIKey")
                    : await configuration.apiKey;
                localVarHeaderParameter["APIKey"] = localVarApiKeyValue;
            }

            if (owner !== undefined) {
                localVarQueryParameter['owner'] = owner;
            }

            if (tenantId !== undefined) {
                localVarQueryParameter['tenantId'] = tenantId;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Deletes a user from a multi-user account. Only a user with an ADMIN right for a multi-user account (TEAM or ENTERPRISE edition) can perform this operation.
         * @summary deleteAccountUser
         * @param {string} email User\&#39;s email
         * @param {string} [owner] The email of account\&#39;s owner
         * @param {string} [tenantId] The tenant id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAccountUser: async (email: string, owner?: string, tenantId?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'email' is not null or undefined
            if (email === null || email === undefined) {
                throw new RequiredError('email','Required parameter email was null or undefined when calling deleteAccountUser.');
            }
            const localVarPath = `/api/v1/users/{email}`
                .replace(`{${"email"}}`, encodeURIComponent(String(email)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("APIKey")
                    : await configuration.apiKey;
                localVarHeaderParameter["APIKey"] = localVarApiKeyValue;
            }

            if (owner !== undefined) {
                localVarQueryParameter['owner'] = owner;
            }

            if (tenantId !== undefined) {
                localVarQueryParameter['tenantId'] = tenantId;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns the information about a single user.
         * @summary getAccountUser
         * @param {string} email User\&#39;s email
         * @param {string} [owner] The email of account\&#39;s owner
         * @param {string} [tenantId] The tenant id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccountUser: async (email: string, owner?: string, tenantId?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'email' is not null or undefined
            if (email === null || email === undefined) {
                throw new RequiredError('email','Required parameter email was null or undefined when calling getAccountUser.');
            }
            const localVarPath = `/api/v1/users/{email}`
                .replace(`{${"email"}}`, encodeURIComponent(String(email)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("APIKey")
                    : await configuration.apiKey;
                localVarHeaderParameter["APIKey"] = localVarApiKeyValue;
            }

            if (owner !== undefined) {
                localVarQueryParameter['owner'] = owner;
            }

            if (tenantId !== undefined) {
                localVarQueryParameter['tenantId'] = tenantId;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns a list of users for the current account. Only a user with an ADMIN role can call this operation.
         * @summary listAccountUsers
         * @param {string} [owner] The email of account\&#39;s owner
         * @param {string} [tenantId] The tenant id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAccountUsers: async (owner?: string, tenantId?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/users`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("APIKey")
                    : await configuration.apiKey;
                localVarHeaderParameter["APIKey"] = localVarApiKeyValue;
            }

            if (owner !== undefined) {
                localVarQueryParameter['owner'] = owner;
            }

            if (tenantId !== undefined) {
                localVarQueryParameter['tenantId'] = tenantId;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates a new user in a multi-user account. Supports changing the account role and/or updating the password. For a free user account, only the password is changed if it is provided.
         * @summary updateAccountUser
         * @param {string} email User\&#39;s email
         * @param {string} [owner] The email of account\&#39;s owner
         * @param {string} [tenantId] The tenant id
         * @param {DqoUserModel} [body] Updated user model with a new role and possibly a new password
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAccountUser: async (email: string, owner?: string, tenantId?: string, body?: DqoUserModel, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'email' is not null or undefined
            if (email === null || email === undefined) {
                throw new RequiredError('email','Required parameter email was null or undefined when calling updateAccountUser.');
            }
            const localVarPath = `/api/v1/users/{email}`
                .replace(`{${"email"}}`, encodeURIComponent(String(email)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("APIKey")
                    : await configuration.apiKey;
                localVarHeaderParameter["APIKey"] = localVarApiKeyValue;
            }

            if (owner !== undefined) {
                localVarQueryParameter['owner'] = owner;
            }

            if (tenantId !== undefined) {
                localVarQueryParameter['tenantId'] = tenantId;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AccountUsersApi - functional programming interface
 * @export
 */
export const AccountUsersApiFp = function(configuration?: Configuration) {
    return {
        /**
         * Changes the password of the given user. The user can change his own password.
         * @summary changeAccountUserPassword
         * @param {string} email User\&#39;s email
         * @param {string} [owner] The email of account\&#39;s owner
         * @param {string} [tenantId] The tenant id
         * @param {string} [body] New password
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async changeAccountUserPassword(email: string, owner?: string, tenantId?: string, body?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await AccountUsersApiAxiosParamCreator(configuration).changeAccountUserPassword(email, owner, tenantId, body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Adds a new user to a multi-user account. Only accounts on a TEAM or ENTERPRISE license can add new users.
         * @summary createAccountUser
         * @param {string} [owner] The email of account\&#39;s owner
         * @param {string} [tenantId] The tenant id
         * @param {DqoUserModel} [body] New user model
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createAccountUser(owner?: string, tenantId?: string, body?: DqoUserModel, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await AccountUsersApiAxiosParamCreator(configuration).createAccountUser(owner, tenantId, body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Deletes a user from a multi-user account. Only a user with an ADMIN right for a multi-user account (TEAM or ENTERPRISE edition) can perform this operation.
         * @summary deleteAccountUser
         * @param {string} email User\&#39;s email
         * @param {string} [owner] The email of account\&#39;s owner
         * @param {string} [tenantId] The tenant id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteAccountUser(email: string, owner?: string, tenantId?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await AccountUsersApiAxiosParamCreator(configuration).deleteAccountUser(email, owner, tenantId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Returns the information about a single user.
         * @summary getAccountUser
         * @param {string} email User\&#39;s email
         * @param {string} [owner] The email of account\&#39;s owner
         * @param {string} [tenantId] The tenant id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAccountUser(email: string, owner?: string, tenantId?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DqoUserModel>> {
            const localVarAxiosArgs = await AccountUsersApiAxiosParamCreator(configuration).getAccountUser(email, owner, tenantId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Returns a list of users for the current account. Only a user with an ADMIN role can call this operation.
         * @summary listAccountUsers
         * @param {string} [owner] The email of account\&#39;s owner
         * @param {string} [tenantId] The tenant id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listAccountUsers(owner?: string, tenantId?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DqoUserModel>>> {
            const localVarAxiosArgs = await AccountUsersApiAxiosParamCreator(configuration).listAccountUsers(owner, tenantId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Updates a new user in a multi-user account. Supports changing the account role and/or updating the password. For a free user account, only the password is changed if it is provided.
         * @summary updateAccountUser
         * @param {string} email User\&#39;s email
         * @param {string} [owner] The email of account\&#39;s owner
         * @param {string} [tenantId] The tenant id
         * @param {DqoUserModel} [body] Updated user model with a new role and possibly a new password
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateAccountUser(email: string, owner?: string, tenantId?: string, body?: DqoUserModel, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await AccountUsersApiAxiosParamCreator(configuration).updateAccountUser(email, owner, tenantId, body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * AccountUsersApi - factory interface
 * @export
 */
export const AccountUsersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * Changes the password of the given user. The user can change his own password.
         * @summary changeAccountUserPassword
         * @param {string} email User\&#39;s email
         * @param {string} [owner] The email of account\&#39;s owner
         * @param {string} [tenantId] The tenant id
         * @param {string} [body] New password
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeAccountUserPassword(email: string, owner?: string, tenantId?: string, body?: string, options?: any): AxiosPromise<void> {
            return AccountUsersApiFp(configuration).changeAccountUserPassword(email, owner, tenantId, body, options).then((request) => request(axios, basePath));
        },
        /**
         * Adds a new user to a multi-user account. Only accounts on a TEAM or ENTERPRISE license can add new users.
         * @summary createAccountUser
         * @param {string} [owner] The email of account\&#39;s owner
         * @param {string} [tenantId] The tenant id
         * @param {DqoUserModel} [body] New user model
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAccountUser(owner?: string, tenantId?: string, body?: DqoUserModel, options?: any): AxiosPromise<void> {
            return AccountUsersApiFp(configuration).createAccountUser(owner, tenantId, body, options).then((request) => request(axios, basePath));
        },
        /**
         * Deletes a user from a multi-user account. Only a user with an ADMIN right for a multi-user account (TEAM or ENTERPRISE edition) can perform this operation.
         * @summary deleteAccountUser
         * @param {string} email User\&#39;s email
         * @param {string} [owner] The email of account\&#39;s owner
         * @param {string} [tenantId] The tenant id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAccountUser(email: string, owner?: string, tenantId?: string, options?: any): AxiosPromise<void> {
            return AccountUsersApiFp(configuration).deleteAccountUser(email, owner, tenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns the information about a single user.
         * @summary getAccountUser
         * @param {string} email User\&#39;s email
         * @param {string} [owner] The email of account\&#39;s owner
         * @param {string} [tenantId] The tenant id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccountUser(email: string, owner?: string, tenantId?: string, options?: any): AxiosPromise<DqoUserModel> {
            return AccountUsersApiFp(configuration).getAccountUser(email, owner, tenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns a list of users for the current account. Only a user with an ADMIN role can call this operation.
         * @summary listAccountUsers
         * @param {string} [owner] The email of account\&#39;s owner
         * @param {string} [tenantId] The tenant id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAccountUsers(owner?: string, tenantId?: string, options?: any): AxiosPromise<Array<DqoUserModel>> {
            return AccountUsersApiFp(configuration).listAccountUsers(owner, tenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * Updates a new user in a multi-user account. Supports changing the account role and/or updating the password. For a free user account, only the password is changed if it is provided.
         * @summary updateAccountUser
         * @param {string} email User\&#39;s email
         * @param {string} [owner] The email of account\&#39;s owner
         * @param {string} [tenantId] The tenant id
         * @param {DqoUserModel} [body] Updated user model with a new role and possibly a new password
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAccountUser(email: string, owner?: string, tenantId?: string, body?: DqoUserModel, options?: any): AxiosPromise<void> {
            return AccountUsersApiFp(configuration).updateAccountUser(email, owner, tenantId, body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AccountUsersApi - interface
 * @export
 * @interface AccountUsersApi
 */
export interface AccountUsersApiInterface {
    /**
     * Changes the password of the given user. The user can change his own password.
     * @summary changeAccountUserPassword
     * @param {string} email User\&#39;s email
     * @param {string} [owner] The email of account\&#39;s owner
     * @param {string} [tenantId] The tenant id
     * @param {string} [body] New password
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountUsersApiInterface
     */
    changeAccountUserPassword(email: string, owner?: string, tenantId?: string, body?: string, options?: any): AxiosPromise<void>;

    /**
     * Adds a new user to a multi-user account. Only accounts on a TEAM or ENTERPRISE license can add new users.
     * @summary createAccountUser
     * @param {string} [owner] The email of account\&#39;s owner
     * @param {string} [tenantId] The tenant id
     * @param {DqoUserModel} [body] New user model
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountUsersApiInterface
     */
    createAccountUser(owner?: string, tenantId?: string, body?: DqoUserModel, options?: any): AxiosPromise<void>;

    /**
     * Deletes a user from a multi-user account. Only a user with an ADMIN right for a multi-user account (TEAM or ENTERPRISE edition) can perform this operation.
     * @summary deleteAccountUser
     * @param {string} email User\&#39;s email
     * @param {string} [owner] The email of account\&#39;s owner
     * @param {string} [tenantId] The tenant id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountUsersApiInterface
     */
    deleteAccountUser(email: string, owner?: string, tenantId?: string, options?: any): AxiosPromise<void>;

    /**
     * Returns the information about a single user.
     * @summary getAccountUser
     * @param {string} email User\&#39;s email
     * @param {string} [owner] The email of account\&#39;s owner
     * @param {string} [tenantId] The tenant id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountUsersApiInterface
     */
    getAccountUser(email: string, owner?: string, tenantId?: string, options?: any): AxiosPromise<DqoUserModel>;

    /**
     * Returns a list of users for the current account. Only a user with an ADMIN role can call this operation.
     * @summary listAccountUsers
     * @param {string} [owner] The email of account\&#39;s owner
     * @param {string} [tenantId] The tenant id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountUsersApiInterface
     */
    listAccountUsers(owner?: string, tenantId?: string, options?: any): AxiosPromise<Array<DqoUserModel>>;

    /**
     * Updates a new user in a multi-user account. Supports changing the account role and/or updating the password. For a free user account, only the password is changed if it is provided.
     * @summary updateAccountUser
     * @param {string} email User\&#39;s email
     * @param {string} [owner] The email of account\&#39;s owner
     * @param {string} [tenantId] The tenant id
     * @param {DqoUserModel} [body] Updated user model with a new role and possibly a new password
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountUsersApiInterface
     */
    updateAccountUser(email: string, owner?: string, tenantId?: string, body?: DqoUserModel, options?: any): AxiosPromise<void>;

}

/**
 * AccountUsersApi - object-oriented interface
 * @export
 * @class AccountUsersApi
 * @extends {BaseAPI}
 */
export class AccountUsersApi extends BaseAPI implements AccountUsersApiInterface {
    /**
     * Changes the password of the given user. The user can change his own password.
     * @summary changeAccountUserPassword
     * @param {string} email User\&#39;s email
     * @param {string} [owner] The email of account\&#39;s owner
     * @param {string} [tenantId] The tenant id
     * @param {string} [body] New password
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountUsersApi
     */
    public changeAccountUserPassword(email: string, owner?: string, tenantId?: string, body?: string, options?: any) {
        return AccountUsersApiFp(this.configuration).changeAccountUserPassword(email, owner, tenantId, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Adds a new user to a multi-user account. Only accounts on a TEAM or ENTERPRISE license can add new users.
     * @summary createAccountUser
     * @param {string} [owner] The email of account\&#39;s owner
     * @param {string} [tenantId] The tenant id
     * @param {DqoUserModel} [body] New user model
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountUsersApi
     */
    public createAccountUser(owner?: string, tenantId?: string, body?: DqoUserModel, options?: any) {
        return AccountUsersApiFp(this.configuration).createAccountUser(owner, tenantId, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Deletes a user from a multi-user account. Only a user with an ADMIN right for a multi-user account (TEAM or ENTERPRISE edition) can perform this operation.
     * @summary deleteAccountUser
     * @param {string} email User\&#39;s email
     * @param {string} [owner] The email of account\&#39;s owner
     * @param {string} [tenantId] The tenant id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountUsersApi
     */
    public deleteAccountUser(email: string, owner?: string, tenantId?: string, options?: any) {
        return AccountUsersApiFp(this.configuration).deleteAccountUser(email, owner, tenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns the information about a single user.
     * @summary getAccountUser
     * @param {string} email User\&#39;s email
     * @param {string} [owner] The email of account\&#39;s owner
     * @param {string} [tenantId] The tenant id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountUsersApi
     */
    public getAccountUser(email: string, owner?: string, tenantId?: string, options?: any) {
        return AccountUsersApiFp(this.configuration).getAccountUser(email, owner, tenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns a list of users for the current account. Only a user with an ADMIN role can call this operation.
     * @summary listAccountUsers
     * @param {string} [owner] The email of account\&#39;s owner
     * @param {string} [tenantId] The tenant id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountUsersApi
     */
    public listAccountUsers(owner?: string, tenantId?: string, options?: any) {
        return AccountUsersApiFp(this.configuration).listAccountUsers(owner, tenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates a new user in a multi-user account. Supports changing the account role and/or updating the password. For a free user account, only the password is changed if it is provided.
     * @summary updateAccountUser
     * @param {string} email User\&#39;s email
     * @param {string} [owner] The email of account\&#39;s owner
     * @param {string} [tenantId] The tenant id
     * @param {DqoUserModel} [body] Updated user model with a new role and possibly a new password
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountUsersApi
     */
    public updateAccountUser(email: string, owner?: string, tenantId?: string, body?: DqoUserModel, options?: any) {
        return AccountUsersApiFp(this.configuration).updateAccountUser(email, owner, tenantId, body, options).then((request) => request(this.axios, this.basePath));
    }

}


/**
 * ApiKeyRequestApi - axios parameter creator
 * @export
 */
export const ApiKeyRequestApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Issues an API Key by the DQO Cloud UI, this operation can also generate a new fresh API key.
         * @summary issueApiKey
         * @param {string} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        issueApiKey: async (body?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/apikey/issueapikey`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("APIKey")
                    : await configuration.apiKey;
                localVarHeaderParameter["APIKey"] = localVarApiKeyValue;
            }

            // authentication firebase_auth required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("firebase_auth", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'text/plain';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves a previously generated API Key given an API Key generation request.
         * @summary pickApiKey
         * @param {string} apiKeyRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pickApiKey: async (apiKeyRequest: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'apiKeyRequest' is not null or undefined
            if (apiKeyRequest === null || apiKeyRequest === undefined) {
                throw new RequiredError('apiKeyRequest','Required parameter apiKeyRequest was null or undefined when calling pickApiKey.');
            }
            const localVarPath = `/api/v1/apikey/request/{apiKeyRequest}`
                .replace(`{${"apiKeyRequest"}}`, encodeURIComponent(String(apiKeyRequest)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Initiates an API Key generation request, generating the most recent API Key version.
         * @summary requestApiKey
         * @param {string} [challenge] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        requestApiKey: async (challenge?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/apikey/request`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (challenge !== undefined) {
                localVarQueryParameter['challenge'] = challenge;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Initiates an API KEY generation process, asking for a specific version of the API key.
         * @summary requestApiKeyVersion
         * @param {number} version 
         * @param {string} [challenge] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        requestApiKeyVersion: async (version: number, challenge?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'version' is not null or undefined
            if (version === null || version === undefined) {
                throw new RequiredError('version','Required parameter version was null or undefined when calling requestApiKeyVersion.');
            }
            const localVarPath = `/api/v1/apikey/request/{version}`
                .replace(`{${"version"}}`, encodeURIComponent(String(version)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (challenge !== undefined) {
                localVarQueryParameter['challenge'] = challenge;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ApiKeyRequestApi - functional programming interface
 * @export
 */
export const ApiKeyRequestApiFp = function(configuration?: Configuration) {
    return {
        /**
         * Issues an API Key by the DQO Cloud UI, this operation can also generate a new fresh API key.
         * @summary issueApiKey
         * @param {string} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async issueApiKey(body?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await ApiKeyRequestApiAxiosParamCreator(configuration).issueApiKey(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Retrieves a previously generated API Key given an API Key generation request.
         * @summary pickApiKey
         * @param {string} apiKeyRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async pickApiKey(apiKeyRequest: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await ApiKeyRequestApiAxiosParamCreator(configuration).pickApiKey(apiKeyRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Initiates an API Key generation request, generating the most recent API Key version.
         * @summary requestApiKey
         * @param {string} [challenge] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async requestApiKey(challenge?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await ApiKeyRequestApiAxiosParamCreator(configuration).requestApiKey(challenge, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Initiates an API KEY generation process, asking for a specific version of the API key.
         * @summary requestApiKeyVersion
         * @param {number} version 
         * @param {string} [challenge] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async requestApiKeyVersion(version: number, challenge?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await ApiKeyRequestApiAxiosParamCreator(configuration).requestApiKeyVersion(version, challenge, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * ApiKeyRequestApi - factory interface
 * @export
 */
export const ApiKeyRequestApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * Issues an API Key by the DQO Cloud UI, this operation can also generate a new fresh API key.
         * @summary issueApiKey
         * @param {string} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        issueApiKey(body?: string, options?: any): AxiosPromise<string> {
            return ApiKeyRequestApiFp(configuration).issueApiKey(body, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves a previously generated API Key given an API Key generation request.
         * @summary pickApiKey
         * @param {string} apiKeyRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pickApiKey(apiKeyRequest: string, options?: any): AxiosPromise<string> {
            return ApiKeyRequestApiFp(configuration).pickApiKey(apiKeyRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Initiates an API Key generation request, generating the most recent API Key version.
         * @summary requestApiKey
         * @param {string} [challenge] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        requestApiKey(challenge?: string, options?: any): AxiosPromise<string> {
            return ApiKeyRequestApiFp(configuration).requestApiKey(challenge, options).then((request) => request(axios, basePath));
        },
        /**
         * Initiates an API KEY generation process, asking for a specific version of the API key.
         * @summary requestApiKeyVersion
         * @param {number} version 
         * @param {string} [challenge] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        requestApiKeyVersion(version: number, challenge?: string, options?: any): AxiosPromise<string> {
            return ApiKeyRequestApiFp(configuration).requestApiKeyVersion(version, challenge, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ApiKeyRequestApi - interface
 * @export
 * @interface ApiKeyRequestApi
 */
export interface ApiKeyRequestApiInterface {
    /**
     * Issues an API Key by the DQO Cloud UI, this operation can also generate a new fresh API key.
     * @summary issueApiKey
     * @param {string} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiKeyRequestApiInterface
     */
    issueApiKey(body?: string, options?: any): AxiosPromise<string>;

    /**
     * Retrieves a previously generated API Key given an API Key generation request.
     * @summary pickApiKey
     * @param {string} apiKeyRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiKeyRequestApiInterface
     */
    pickApiKey(apiKeyRequest: string, options?: any): AxiosPromise<string>;

    /**
     * Initiates an API Key generation request, generating the most recent API Key version.
     * @summary requestApiKey
     * @param {string} [challenge] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiKeyRequestApiInterface
     */
    requestApiKey(challenge?: string, options?: any): AxiosPromise<string>;

    /**
     * Initiates an API KEY generation process, asking for a specific version of the API key.
     * @summary requestApiKeyVersion
     * @param {number} version 
     * @param {string} [challenge] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiKeyRequestApiInterface
     */
    requestApiKeyVersion(version: number, challenge?: string, options?: any): AxiosPromise<string>;

}

/**
 * ApiKeyRequestApi - object-oriented interface
 * @export
 * @class ApiKeyRequestApi
 * @extends {BaseAPI}
 */
export class ApiKeyRequestApi extends BaseAPI implements ApiKeyRequestApiInterface {
    /**
     * Issues an API Key by the DQO Cloud UI, this operation can also generate a new fresh API key.
     * @summary issueApiKey
     * @param {string} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiKeyRequestApi
     */
    public issueApiKey(body?: string, options?: any) {
        return ApiKeyRequestApiFp(this.configuration).issueApiKey(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves a previously generated API Key given an API Key generation request.
     * @summary pickApiKey
     * @param {string} apiKeyRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiKeyRequestApi
     */
    public pickApiKey(apiKeyRequest: string, options?: any) {
        return ApiKeyRequestApiFp(this.configuration).pickApiKey(apiKeyRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Initiates an API Key generation request, generating the most recent API Key version.
     * @summary requestApiKey
     * @param {string} [challenge] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiKeyRequestApi
     */
    public requestApiKey(challenge?: string, options?: any) {
        return ApiKeyRequestApiFp(this.configuration).requestApiKey(challenge, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Initiates an API KEY generation process, asking for a specific version of the API key.
     * @summary requestApiKeyVersion
     * @param {number} version 
     * @param {string} [challenge] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiKeyRequestApi
     */
    public requestApiKeyVersion(version: number, challenge?: string, options?: any) {
        return ApiKeyRequestApiFp(this.configuration).requestApiKeyVersion(version, challenge, options).then((request) => request(this.axios, this.basePath));
    }

}


/**
 * DSCCApi - axios parameter creator
 * @export
 */
export const DSCCApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary issueDSCCRefreshToken
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        issueDSCCRefreshToken: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/dscc`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DSCCApi - functional programming interface
 * @export
 */
export const DSCCApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary issueDSCCRefreshToken
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async issueDSCCRefreshToken(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await DSCCApiAxiosParamCreator(configuration).issueDSCCRefreshToken(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * DSCCApi - factory interface
 * @export
 */
export const DSCCApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary issueDSCCRefreshToken
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        issueDSCCRefreshToken(options?: any): AxiosPromise<string> {
            return DSCCApiFp(configuration).issueDSCCRefreshToken(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DSCCApi - interface
 * @export
 * @interface DSCCApi
 */
export interface DSCCApiInterface {
    /**
     * 
     * @summary issueDSCCRefreshToken
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DSCCApiInterface
     */
    issueDSCCRefreshToken(options?: any): AxiosPromise<string>;

}

/**
 * DSCCApi - object-oriented interface
 * @export
 * @class DSCCApi
 * @extends {BaseAPI}
 */
export class DSCCApi extends BaseAPI implements DSCCApiInterface {
    /**
     * 
     * @summary issueDSCCRefreshToken
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DSCCApi
     */
    public issueDSCCRefreshToken(options?: any) {
        return DSCCApiFp(this.configuration).issueDSCCRefreshToken(options).then((request) => request(this.axios, this.basePath));
    }

}


/**
 * DataDomainsApi - axios parameter creator
 * @export
 */
export const DataDomainsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Adds a new data domain. Only accounts onan ENTERPRISE license can add new data domains.
         * @summary createDataDomain
         * @param {string} dataDomain Data domain name
         * @param {string} [owner] The email of account\&#39;s owner
         * @param {string} [tenantId] The tenant id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDataDomain: async (dataDomain: string, owner?: string, tenantId?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'dataDomain' is not null or undefined
            if (dataDomain === null || dataDomain === undefined) {
                throw new RequiredError('dataDomain','Required parameter dataDomain was null or undefined when calling createDataDomain.');
            }
            const localVarPath = `/api/v1/datadomains/{dataDomain}`
                .replace(`{${"dataDomain"}}`, encodeURIComponent(String(dataDomain)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("APIKey")
                    : await configuration.apiKey;
                localVarHeaderParameter["APIKey"] = localVarApiKeyValue;
            }

            if (owner !== undefined) {
                localVarQueryParameter['owner'] = owner;
            }

            if (tenantId !== undefined) {
                localVarQueryParameter['tenantId'] = tenantId;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Deletes a data domain. Only a user with an ADMIN right for an ENTERPRISE edition can perform this operation. A deleted data domain cannot be recreated under the same name.
         * @summary deleteDataDomain
         * @param {string} dataDomain Data domain name
         * @param {string} [owner] The email of account\&#39;s owner
         * @param {string} [tenantId] The tenant id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDataDomain: async (dataDomain: string, owner?: string, tenantId?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'dataDomain' is not null or undefined
            if (dataDomain === null || dataDomain === undefined) {
                throw new RequiredError('dataDomain','Required parameter dataDomain was null or undefined when calling deleteDataDomain.');
            }
            const localVarPath = `/api/v1/datadomains/{dataDomain}`
                .replace(`{${"dataDomain"}}`, encodeURIComponent(String(dataDomain)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("APIKey")
                    : await configuration.apiKey;
                localVarHeaderParameter["APIKey"] = localVarApiKeyValue;
            }

            if (owner !== undefined) {
                localVarQueryParameter['owner'] = owner;
            }

            if (tenantId !== undefined) {
                localVarQueryParameter['tenantId'] = tenantId;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns a list of data domains. Only a user with an ADMIN role can call this operation.
         * @summary getDataDomainsList
         * @param {string} [owner] The email of account\&#39;s owner
         * @param {string} [tenantId] The tenant id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDataDomainsList: async (owner?: string, tenantId?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/datadomains`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("APIKey")
                    : await configuration.apiKey;
                localVarHeaderParameter["APIKey"] = localVarApiKeyValue;
            }

            if (owner !== undefined) {
                localVarQueryParameter['owner'] = owner;
            }

            if (tenantId !== undefined) {
                localVarQueryParameter['tenantId'] = tenantId;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DataDomainsApi - functional programming interface
 * @export
 */
export const DataDomainsApiFp = function(configuration?: Configuration) {
    return {
        /**
         * Adds a new data domain. Only accounts onan ENTERPRISE license can add new data domains.
         * @summary createDataDomain
         * @param {string} dataDomain Data domain name
         * @param {string} [owner] The email of account\&#39;s owner
         * @param {string} [tenantId] The tenant id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createDataDomain(dataDomain: string, owner?: string, tenantId?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DataDomainModel>> {
            const localVarAxiosArgs = await DataDomainsApiAxiosParamCreator(configuration).createDataDomain(dataDomain, owner, tenantId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Deletes a data domain. Only a user with an ADMIN right for an ENTERPRISE edition can perform this operation. A deleted data domain cannot be recreated under the same name.
         * @summary deleteDataDomain
         * @param {string} dataDomain Data domain name
         * @param {string} [owner] The email of account\&#39;s owner
         * @param {string} [tenantId] The tenant id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteDataDomain(dataDomain: string, owner?: string, tenantId?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await DataDomainsApiAxiosParamCreator(configuration).deleteDataDomain(dataDomain, owner, tenantId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Returns a list of data domains. Only a user with an ADMIN role can call this operation.
         * @summary getDataDomainsList
         * @param {string} [owner] The email of account\&#39;s owner
         * @param {string} [tenantId] The tenant id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDataDomainsList(owner?: string, tenantId?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DataDomainModel>>> {
            const localVarAxiosArgs = await DataDomainsApiAxiosParamCreator(configuration).getDataDomainsList(owner, tenantId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * DataDomainsApi - factory interface
 * @export
 */
export const DataDomainsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * Adds a new data domain. Only accounts onan ENTERPRISE license can add new data domains.
         * @summary createDataDomain
         * @param {string} dataDomain Data domain name
         * @param {string} [owner] The email of account\&#39;s owner
         * @param {string} [tenantId] The tenant id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDataDomain(dataDomain: string, owner?: string, tenantId?: string, options?: any): AxiosPromise<DataDomainModel> {
            return DataDomainsApiFp(configuration).createDataDomain(dataDomain, owner, tenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * Deletes a data domain. Only a user with an ADMIN right for an ENTERPRISE edition can perform this operation. A deleted data domain cannot be recreated under the same name.
         * @summary deleteDataDomain
         * @param {string} dataDomain Data domain name
         * @param {string} [owner] The email of account\&#39;s owner
         * @param {string} [tenantId] The tenant id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDataDomain(dataDomain: string, owner?: string, tenantId?: string, options?: any): AxiosPromise<void> {
            return DataDomainsApiFp(configuration).deleteDataDomain(dataDomain, owner, tenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns a list of data domains. Only a user with an ADMIN role can call this operation.
         * @summary getDataDomainsList
         * @param {string} [owner] The email of account\&#39;s owner
         * @param {string} [tenantId] The tenant id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDataDomainsList(owner?: string, tenantId?: string, options?: any): AxiosPromise<Array<DataDomainModel>> {
            return DataDomainsApiFp(configuration).getDataDomainsList(owner, tenantId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DataDomainsApi - interface
 * @export
 * @interface DataDomainsApi
 */
export interface DataDomainsApiInterface {
    /**
     * Adds a new data domain. Only accounts onan ENTERPRISE license can add new data domains.
     * @summary createDataDomain
     * @param {string} dataDomain Data domain name
     * @param {string} [owner] The email of account\&#39;s owner
     * @param {string} [tenantId] The tenant id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataDomainsApiInterface
     */
    createDataDomain(dataDomain: string, owner?: string, tenantId?: string, options?: any): AxiosPromise<DataDomainModel>;

    /**
     * Deletes a data domain. Only a user with an ADMIN right for an ENTERPRISE edition can perform this operation. A deleted data domain cannot be recreated under the same name.
     * @summary deleteDataDomain
     * @param {string} dataDomain Data domain name
     * @param {string} [owner] The email of account\&#39;s owner
     * @param {string} [tenantId] The tenant id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataDomainsApiInterface
     */
    deleteDataDomain(dataDomain: string, owner?: string, tenantId?: string, options?: any): AxiosPromise<void>;

    /**
     * Returns a list of data domains. Only a user with an ADMIN role can call this operation.
     * @summary getDataDomainsList
     * @param {string} [owner] The email of account\&#39;s owner
     * @param {string} [tenantId] The tenant id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataDomainsApiInterface
     */
    getDataDomainsList(owner?: string, tenantId?: string, options?: any): AxiosPromise<Array<DataDomainModel>>;

}

/**
 * DataDomainsApi - object-oriented interface
 * @export
 * @class DataDomainsApi
 * @extends {BaseAPI}
 */
export class DataDomainsApi extends BaseAPI implements DataDomainsApiInterface {
    /**
     * Adds a new data domain. Only accounts onan ENTERPRISE license can add new data domains.
     * @summary createDataDomain
     * @param {string} dataDomain Data domain name
     * @param {string} [owner] The email of account\&#39;s owner
     * @param {string} [tenantId] The tenant id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataDomainsApi
     */
    public createDataDomain(dataDomain: string, owner?: string, tenantId?: string, options?: any) {
        return DataDomainsApiFp(this.configuration).createDataDomain(dataDomain, owner, tenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Deletes a data domain. Only a user with an ADMIN right for an ENTERPRISE edition can perform this operation. A deleted data domain cannot be recreated under the same name.
     * @summary deleteDataDomain
     * @param {string} dataDomain Data domain name
     * @param {string} [owner] The email of account\&#39;s owner
     * @param {string} [tenantId] The tenant id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataDomainsApi
     */
    public deleteDataDomain(dataDomain: string, owner?: string, tenantId?: string, options?: any) {
        return DataDomainsApiFp(this.configuration).deleteDataDomain(dataDomain, owner, tenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns a list of data domains. Only a user with an ADMIN role can call this operation.
     * @summary getDataDomainsList
     * @param {string} [owner] The email of account\&#39;s owner
     * @param {string} [tenantId] The tenant id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataDomainsApi
     */
    public getDataDomainsList(owner?: string, tenantId?: string, options?: any) {
        return DataDomainsApiFp(this.configuration).getDataDomainsList(owner, tenantId, options).then((request) => request(this.axios, this.basePath));
    }

}


/**
 * LookerStudioKeyRequestApi - axios parameter creator
 * @export
 */
export const LookerStudioKeyRequestApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Issues an API key for accessing DQOps data quality dashboards from Looker Studio
         * @summary issueLookerStudioApiKey
         * @param {string} [dataDomain] The name of the data domain
         * @param {string} [owner] The email of account\&#39;s owner
         * @param {string} [tenantId] The tenant id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        issueLookerStudioApiKey: async (dataDomain?: string, owner?: string, tenantId?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/lookerstudiokey/issue`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("APIKey")
                    : await configuration.apiKey;
                localVarHeaderParameter["APIKey"] = localVarApiKeyValue;
            }

            if (dataDomain !== undefined) {
                localVarQueryParameter['dataDomain'] = dataDomain;
            }

            if (owner !== undefined) {
                localVarQueryParameter['owner'] = owner;
            }

            if (tenantId !== undefined) {
                localVarQueryParameter['tenantId'] = tenantId;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * LookerStudioKeyRequestApi - functional programming interface
 * @export
 */
export const LookerStudioKeyRequestApiFp = function(configuration?: Configuration) {
    return {
        /**
         * Issues an API key for accessing DQOps data quality dashboards from Looker Studio
         * @summary issueLookerStudioApiKey
         * @param {string} [dataDomain] The name of the data domain
         * @param {string} [owner] The email of account\&#39;s owner
         * @param {string} [tenantId] The tenant id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async issueLookerStudioApiKey(dataDomain?: string, owner?: string, tenantId?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await LookerStudioKeyRequestApiAxiosParamCreator(configuration).issueLookerStudioApiKey(dataDomain, owner, tenantId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * LookerStudioKeyRequestApi - factory interface
 * @export
 */
export const LookerStudioKeyRequestApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * Issues an API key for accessing DQOps data quality dashboards from Looker Studio
         * @summary issueLookerStudioApiKey
         * @param {string} [dataDomain] The name of the data domain
         * @param {string} [owner] The email of account\&#39;s owner
         * @param {string} [tenantId] The tenant id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        issueLookerStudioApiKey(dataDomain?: string, owner?: string, tenantId?: string, options?: any): AxiosPromise<string> {
            return LookerStudioKeyRequestApiFp(configuration).issueLookerStudioApiKey(dataDomain, owner, tenantId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * LookerStudioKeyRequestApi - interface
 * @export
 * @interface LookerStudioKeyRequestApi
 */
export interface LookerStudioKeyRequestApiInterface {
    /**
     * Issues an API key for accessing DQOps data quality dashboards from Looker Studio
     * @summary issueLookerStudioApiKey
     * @param {string} [dataDomain] The name of the data domain
     * @param {string} [owner] The email of account\&#39;s owner
     * @param {string} [tenantId] The tenant id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LookerStudioKeyRequestApiInterface
     */
    issueLookerStudioApiKey(dataDomain?: string, owner?: string, tenantId?: string, options?: any): AxiosPromise<string>;

}

/**
 * LookerStudioKeyRequestApi - object-oriented interface
 * @export
 * @class LookerStudioKeyRequestApi
 * @extends {BaseAPI}
 */
export class LookerStudioKeyRequestApi extends BaseAPI implements LookerStudioKeyRequestApiInterface {
    /**
     * Issues an API key for accessing DQOps data quality dashboards from Looker Studio
     * @summary issueLookerStudioApiKey
     * @param {string} [dataDomain] The name of the data domain
     * @param {string} [owner] The email of account\&#39;s owner
     * @param {string} [tenantId] The tenant id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LookerStudioKeyRequestApi
     */
    public issueLookerStudioApiKey(dataDomain?: string, owner?: string, tenantId?: string, options?: any) {
        return LookerStudioKeyRequestApiFp(this.configuration).issueLookerStudioApiKey(dataDomain, owner, tenantId, options).then((request) => request(this.axios, this.basePath));
    }

}


/**
 * RefreshTokenIssueApi - axios parameter creator
 * @export
 */
export const RefreshTokenIssueApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Issues a ticket granting ticket that authorizes DQOps Cloud to issue a DQOps instance refresh token for a user that logs into DQOps Cloud.
         * @summary issueLoginTicketGrantingTicketToken
         * @param {string} [owner] The email of account\&#39;s owner
         * @param {string} [tenantId] The tenant id
         * @param {UserLoginTicketGrantingTicketRequest} [body] tgtRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        issueLoginTicketGrantingTicketToken: async (owner?: string, tenantId?: string, body?: UserLoginTicketGrantingTicketRequest, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/refreshtokens/login_tgt`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("APIKey")
                    : await configuration.apiKey;
                localVarHeaderParameter["APIKey"] = localVarApiKeyValue;
            }

            if (owner !== undefined) {
                localVarQueryParameter['owner'] = owner;
            }

            if (tenantId !== undefined) {
                localVarQueryParameter['tenantId'] = tenantId;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RefreshTokenIssueApi - functional programming interface
 * @export
 */
export const RefreshTokenIssueApiFp = function(configuration?: Configuration) {
    return {
        /**
         * Issues a ticket granting ticket that authorizes DQOps Cloud to issue a DQOps instance refresh token for a user that logs into DQOps Cloud.
         * @summary issueLoginTicketGrantingTicketToken
         * @param {string} [owner] The email of account\&#39;s owner
         * @param {string} [tenantId] The tenant id
         * @param {UserLoginTicketGrantingTicketRequest} [body] tgtRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async issueLoginTicketGrantingTicketToken(owner?: string, tenantId?: string, body?: UserLoginTicketGrantingTicketRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await RefreshTokenIssueApiAxiosParamCreator(configuration).issueLoginTicketGrantingTicketToken(owner, tenantId, body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * RefreshTokenIssueApi - factory interface
 * @export
 */
export const RefreshTokenIssueApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * Issues a ticket granting ticket that authorizes DQOps Cloud to issue a DQOps instance refresh token for a user that logs into DQOps Cloud.
         * @summary issueLoginTicketGrantingTicketToken
         * @param {string} [owner] The email of account\&#39;s owner
         * @param {string} [tenantId] The tenant id
         * @param {UserLoginTicketGrantingTicketRequest} [body] tgtRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        issueLoginTicketGrantingTicketToken(owner?: string, tenantId?: string, body?: UserLoginTicketGrantingTicketRequest, options?: any): AxiosPromise<string> {
            return RefreshTokenIssueApiFp(configuration).issueLoginTicketGrantingTicketToken(owner, tenantId, body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * RefreshTokenIssueApi - interface
 * @export
 * @interface RefreshTokenIssueApi
 */
export interface RefreshTokenIssueApiInterface {
    /**
     * Issues a ticket granting ticket that authorizes DQOps Cloud to issue a DQOps instance refresh token for a user that logs into DQOps Cloud.
     * @summary issueLoginTicketGrantingTicketToken
     * @param {string} [owner] The email of account\&#39;s owner
     * @param {string} [tenantId] The tenant id
     * @param {UserLoginTicketGrantingTicketRequest} [body] tgtRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RefreshTokenIssueApiInterface
     */
    issueLoginTicketGrantingTicketToken(owner?: string, tenantId?: string, body?: UserLoginTicketGrantingTicketRequest, options?: any): AxiosPromise<string>;

}

/**
 * RefreshTokenIssueApi - object-oriented interface
 * @export
 * @class RefreshTokenIssueApi
 * @extends {BaseAPI}
 */
export class RefreshTokenIssueApi extends BaseAPI implements RefreshTokenIssueApiInterface {
    /**
     * Issues a ticket granting ticket that authorizes DQOps Cloud to issue a DQOps instance refresh token for a user that logs into DQOps Cloud.
     * @summary issueLoginTicketGrantingTicketToken
     * @param {string} [owner] The email of account\&#39;s owner
     * @param {string} [tenantId] The tenant id
     * @param {UserLoginTicketGrantingTicketRequest} [body] tgtRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RefreshTokenIssueApi
     */
    public issueLoginTicketGrantingTicketToken(owner?: string, tenantId?: string, body?: UserLoginTicketGrantingTicketRequest, options?: any) {
        return RefreshTokenIssueApiFp(this.configuration).issueLoginTicketGrantingTicketToken(owner, tenantId, body, options).then((request) => request(this.axios, this.basePath));
    }

}


/**
 * TenantApi - axios parameter creator
 * @export
 */
export const TenantApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary getTenantApiKeyForFileExchange
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTenantApiKeyForFileExchange: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/tenant/fileexchangeapikey`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getTenantApiKeyForLookerStudio
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTenantApiKeyForLookerStudio: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/tenant/lookerstudioapikey`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TenantApi - functional programming interface
 * @export
 */
export const TenantApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary getTenantApiKeyForFileExchange
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTenantApiKeyForFileExchange(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await TenantApiAxiosParamCreator(configuration).getTenantApiKeyForFileExchange(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary getTenantApiKeyForLookerStudio
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTenantApiKeyForLookerStudio(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await TenantApiAxiosParamCreator(configuration).getTenantApiKeyForLookerStudio(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * TenantApi - factory interface
 * @export
 */
export const TenantApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary getTenantApiKeyForFileExchange
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTenantApiKeyForFileExchange(options?: any): AxiosPromise<string> {
            return TenantApiFp(configuration).getTenantApiKeyForFileExchange(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary getTenantApiKeyForLookerStudio
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTenantApiKeyForLookerStudio(options?: any): AxiosPromise<string> {
            return TenantApiFp(configuration).getTenantApiKeyForLookerStudio(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TenantApi - interface
 * @export
 * @interface TenantApi
 */
export interface TenantApiInterface {
    /**
     * 
     * @summary getTenantApiKeyForFileExchange
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantApiInterface
     */
    getTenantApiKeyForFileExchange(options?: any): AxiosPromise<string>;

    /**
     * 
     * @summary getTenantApiKeyForLookerStudio
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantApiInterface
     */
    getTenantApiKeyForLookerStudio(options?: any): AxiosPromise<string>;

}

/**
 * TenantApi - object-oriented interface
 * @export
 * @class TenantApi
 * @extends {BaseAPI}
 */
export class TenantApi extends BaseAPI implements TenantApiInterface {
    /**
     * 
     * @summary getTenantApiKeyForFileExchange
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantApi
     */
    public getTenantApiKeyForFileExchange(options?: any) {
        return TenantApiFp(this.configuration).getTenantApiKeyForFileExchange(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary getTenantApiKeyForLookerStudio
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantApi
     */
    public getTenantApiKeyForLookerStudio(options?: any) {
        return TenantApiFp(this.configuration).getTenantApiKeyForLookerStudio(options).then((request) => request(this.axios, this.basePath));
    }

}


/**
 * TenantDataWarehouseApi - axios parameter creator
 * @export
 */
export const TenantDataWarehouseApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Refreshes a native table.
         * @summary refreshNativeTable
         * @param {string} [owner] The email of account\&#39;s owner
         * @param {string} [tenantId] The tenant id
         * @param {string} [table] Table
         * @param {RefreshTableRequest} [body] refreshTableRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refreshNativeTable: async (owner?: string, tenantId?: string, table?: string, body?: RefreshTableRequest, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/tenantdatawarehouse/refresh/table`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("APIKey")
                    : await configuration.apiKey;
                localVarHeaderParameter["APIKey"] = localVarApiKeyValue;
            }

            if (owner !== undefined) {
                localVarQueryParameter['owner'] = owner;
            }

            if (tenantId !== undefined) {
                localVarQueryParameter['tenantId'] = tenantId;
            }

            if (table !== undefined) {
                localVarQueryParameter['table'] = table;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TenantDataWarehouseApi - functional programming interface
 * @export
 */
export const TenantDataWarehouseApiFp = function(configuration?: Configuration) {
    return {
        /**
         * Refreshes a native table.
         * @summary refreshNativeTable
         * @param {string} [owner] The email of account\&#39;s owner
         * @param {string} [tenantId] The tenant id
         * @param {string} [table] Table
         * @param {RefreshTableRequest} [body] refreshTableRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async refreshNativeTable(owner?: string, tenantId?: string, table?: string, body?: RefreshTableRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await TenantDataWarehouseApiAxiosParamCreator(configuration).refreshNativeTable(owner, tenantId, table, body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * TenantDataWarehouseApi - factory interface
 * @export
 */
export const TenantDataWarehouseApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * Refreshes a native table.
         * @summary refreshNativeTable
         * @param {string} [owner] The email of account\&#39;s owner
         * @param {string} [tenantId] The tenant id
         * @param {string} [table] Table
         * @param {RefreshTableRequest} [body] refreshTableRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refreshNativeTable(owner?: string, tenantId?: string, table?: string, body?: RefreshTableRequest, options?: any): AxiosPromise<string> {
            return TenantDataWarehouseApiFp(configuration).refreshNativeTable(owner, tenantId, table, body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TenantDataWarehouseApi - interface
 * @export
 * @interface TenantDataWarehouseApi
 */
export interface TenantDataWarehouseApiInterface {
    /**
     * Refreshes a native table.
     * @summary refreshNativeTable
     * @param {string} [owner] The email of account\&#39;s owner
     * @param {string} [tenantId] The tenant id
     * @param {string} [table] Table
     * @param {RefreshTableRequest} [body] refreshTableRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantDataWarehouseApiInterface
     */
    refreshNativeTable(owner?: string, tenantId?: string, table?: string, body?: RefreshTableRequest, options?: any): AxiosPromise<string>;

}

/**
 * TenantDataWarehouseApi - object-oriented interface
 * @export
 * @class TenantDataWarehouseApi
 * @extends {BaseAPI}
 */
export class TenantDataWarehouseApi extends BaseAPI implements TenantDataWarehouseApiInterface {
    /**
     * Refreshes a native table.
     * @summary refreshNativeTable
     * @param {string} [owner] The email of account\&#39;s owner
     * @param {string} [tenantId] The tenant id
     * @param {string} [table] Table
     * @param {RefreshTableRequest} [body] refreshTableRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantDataWarehouseApi
     */
    public refreshNativeTable(owner?: string, tenantId?: string, table?: string, body?: RefreshTableRequest, options?: any) {
        return TenantDataWarehouseApiFp(this.configuration).refreshNativeTable(owner, tenantId, table, body, options).then((request) => request(this.axios, this.basePath));
    }

}


/**
 * TenantProvisioningApi - axios parameter creator
 * @export
 */
export const TenantProvisioningApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary createIdentityPlatformTenant
         * @param {IdpTenantProvisioningModel} [body] Tenant create model
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createIdentityPlatformTenant: async (body?: IdpTenantProvisioningModel, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/tenantprovisioning`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary deleteApiKeyForTenantCloudInstance
         * @param {string} account Account name
         * @param {string} [domain] Domain
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteApiKeyForTenantCloudInstance: async (account: string, domain?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'account' is not null or undefined
            if (account === null || account === undefined) {
                throw new RequiredError('account','Required parameter account was null or undefined when calling deleteApiKeyForTenantCloudInstance.');
            }
            const localVarPath = `/api/issuevmpairingkey/{account}`
                .replace(`{${"account"}}`, encodeURIComponent(String(account)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (domain !== undefined) {
                localVarQueryParameter['domain'] = domain;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary deleteIdentityPlatformTenant
         * @param {string} account Account name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteIdentityPlatformTenant: async (account: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'account' is not null or undefined
            if (account === null || account === undefined) {
                throw new RequiredError('account','Required parameter account was null or undefined when calling deleteIdentityPlatformTenant.');
            }
            const localVarPath = `/api/tenantprovisioning/{account}`
                .replace(`{${"account"}}`, encodeURIComponent(String(account)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getApiKeyForTenantCloudInstance
         * @param {string} account Account name
         * @param {string} [domain] Domain
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiKeyForTenantCloudInstance: async (account: string, domain?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'account' is not null or undefined
            if (account === null || account === undefined) {
                throw new RequiredError('account','Required parameter account was null or undefined when calling getApiKeyForTenantCloudInstance.');
            }
            const localVarPath = `/api/issuevmpairingkey/{account}`
                .replace(`{${"account"}}`, encodeURIComponent(String(account)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (domain !== undefined) {
                localVarQueryParameter['domain'] = domain;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getIdentityPlatformTenant
         * @param {string} account Account name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIdentityPlatformTenant: async (account: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'account' is not null or undefined
            if (account === null || account === undefined) {
                throw new RequiredError('account','Required parameter account was null or undefined when calling getIdentityPlatformTenant.');
            }
            const localVarPath = `/api/tenantprovisioning/{account}`
                .replace(`{${"account"}}`, encodeURIComponent(String(account)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary issueApiKeyForTenantCloudInstance
         * @param {string} account Account name
         * @param {string} [domain] Domain
         * @param {IdpTenantProvisioningModel} [body] Tenant api key model - ignored
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        issueApiKeyForTenantCloudInstance: async (account: string, domain?: string, body?: IdpTenantProvisioningModel, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'account' is not null or undefined
            if (account === null || account === undefined) {
                throw new RequiredError('account','Required parameter account was null or undefined when calling issueApiKeyForTenantCloudInstance.');
            }
            const localVarPath = `/api/issuevmpairingkey/{account}`
                .replace(`{${"account"}}`, encodeURIComponent(String(account)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (domain !== undefined) {
                localVarQueryParameter['domain'] = domain;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary reissueApiKeyForTenantCloudInstance
         * @param {string} account Account name
         * @param {string} [domain] Domain
         * @param {IdpTenantProvisioningModel} [body] Tenant api key model - ignored
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reissueApiKeyForTenantCloudInstance: async (account: string, domain?: string, body?: IdpTenantProvisioningModel, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'account' is not null or undefined
            if (account === null || account === undefined) {
                throw new RequiredError('account','Required parameter account was null or undefined when calling reissueApiKeyForTenantCloudInstance.');
            }
            const localVarPath = `/api/issuevmpairingkey/{account}`
                .replace(`{${"account"}}`, encodeURIComponent(String(account)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (domain !== undefined) {
                localVarQueryParameter['domain'] = domain;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary updateIdentityPlatformTenant
         * @param {string} account Account name
         * @param {IdpTenantProvisioningModel} [body] New tenant provisioning model
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateIdentityPlatformTenant: async (account: string, body?: IdpTenantProvisioningModel, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'account' is not null or undefined
            if (account === null || account === undefined) {
                throw new RequiredError('account','Required parameter account was null or undefined when calling updateIdentityPlatformTenant.');
            }
            const localVarPath = `/api/tenantprovisioning/{account}`
                .replace(`{${"account"}}`, encodeURIComponent(String(account)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TenantProvisioningApi - functional programming interface
 * @export
 */
export const TenantProvisioningApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary createIdentityPlatformTenant
         * @param {IdpTenantProvisioningModel} [body] Tenant create model
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createIdentityPlatformTenant(body?: IdpTenantProvisioningModel, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IdpTenantProvisioningModel>> {
            const localVarAxiosArgs = await TenantProvisioningApiAxiosParamCreator(configuration).createIdentityPlatformTenant(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary deleteApiKeyForTenantCloudInstance
         * @param {string} account Account name
         * @param {string} [domain] Domain
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteApiKeyForTenantCloudInstance(account: string, domain?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await TenantProvisioningApiAxiosParamCreator(configuration).deleteApiKeyForTenantCloudInstance(account, domain, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary deleteIdentityPlatformTenant
         * @param {string} account Account name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteIdentityPlatformTenant(account: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await TenantProvisioningApiAxiosParamCreator(configuration).deleteIdentityPlatformTenant(account, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary getApiKeyForTenantCloudInstance
         * @param {string} account Account name
         * @param {string} [domain] Domain
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getApiKeyForTenantCloudInstance(account: string, domain?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IdpTenantPairingKeyModel>> {
            const localVarAxiosArgs = await TenantProvisioningApiAxiosParamCreator(configuration).getApiKeyForTenantCloudInstance(account, domain, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary getIdentityPlatformTenant
         * @param {string} account Account name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getIdentityPlatformTenant(account: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IdpTenantProvisioningModel>> {
            const localVarAxiosArgs = await TenantProvisioningApiAxiosParamCreator(configuration).getIdentityPlatformTenant(account, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary issueApiKeyForTenantCloudInstance
         * @param {string} account Account name
         * @param {string} [domain] Domain
         * @param {IdpTenantProvisioningModel} [body] Tenant api key model - ignored
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async issueApiKeyForTenantCloudInstance(account: string, domain?: string, body?: IdpTenantProvisioningModel, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IdpTenantPairingKeyModel>> {
            const localVarAxiosArgs = await TenantProvisioningApiAxiosParamCreator(configuration).issueApiKeyForTenantCloudInstance(account, domain, body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary reissueApiKeyForTenantCloudInstance
         * @param {string} account Account name
         * @param {string} [domain] Domain
         * @param {IdpTenantProvisioningModel} [body] Tenant api key model - ignored
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reissueApiKeyForTenantCloudInstance(account: string, domain?: string, body?: IdpTenantProvisioningModel, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IdpTenantPairingKeyModel>> {
            const localVarAxiosArgs = await TenantProvisioningApiAxiosParamCreator(configuration).reissueApiKeyForTenantCloudInstance(account, domain, body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary updateIdentityPlatformTenant
         * @param {string} account Account name
         * @param {IdpTenantProvisioningModel} [body] New tenant provisioning model
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateIdentityPlatformTenant(account: string, body?: IdpTenantProvisioningModel, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IdpTenantProvisioningModel>> {
            const localVarAxiosArgs = await TenantProvisioningApiAxiosParamCreator(configuration).updateIdentityPlatformTenant(account, body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * TenantProvisioningApi - factory interface
 * @export
 */
export const TenantProvisioningApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary createIdentityPlatformTenant
         * @param {IdpTenantProvisioningModel} [body] Tenant create model
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createIdentityPlatformTenant(body?: IdpTenantProvisioningModel, options?: any): AxiosPromise<IdpTenantProvisioningModel> {
            return TenantProvisioningApiFp(configuration).createIdentityPlatformTenant(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary deleteApiKeyForTenantCloudInstance
         * @param {string} account Account name
         * @param {string} [domain] Domain
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteApiKeyForTenantCloudInstance(account: string, domain?: string, options?: any): AxiosPromise<void> {
            return TenantProvisioningApiFp(configuration).deleteApiKeyForTenantCloudInstance(account, domain, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary deleteIdentityPlatformTenant
         * @param {string} account Account name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteIdentityPlatformTenant(account: string, options?: any): AxiosPromise<void> {
            return TenantProvisioningApiFp(configuration).deleteIdentityPlatformTenant(account, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary getApiKeyForTenantCloudInstance
         * @param {string} account Account name
         * @param {string} [domain] Domain
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiKeyForTenantCloudInstance(account: string, domain?: string, options?: any): AxiosPromise<IdpTenantPairingKeyModel> {
            return TenantProvisioningApiFp(configuration).getApiKeyForTenantCloudInstance(account, domain, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary getIdentityPlatformTenant
         * @param {string} account Account name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIdentityPlatformTenant(account: string, options?: any): AxiosPromise<IdpTenantProvisioningModel> {
            return TenantProvisioningApiFp(configuration).getIdentityPlatformTenant(account, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary issueApiKeyForTenantCloudInstance
         * @param {string} account Account name
         * @param {string} [domain] Domain
         * @param {IdpTenantProvisioningModel} [body] Tenant api key model - ignored
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        issueApiKeyForTenantCloudInstance(account: string, domain?: string, body?: IdpTenantProvisioningModel, options?: any): AxiosPromise<IdpTenantPairingKeyModel> {
            return TenantProvisioningApiFp(configuration).issueApiKeyForTenantCloudInstance(account, domain, body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary reissueApiKeyForTenantCloudInstance
         * @param {string} account Account name
         * @param {string} [domain] Domain
         * @param {IdpTenantProvisioningModel} [body] Tenant api key model - ignored
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reissueApiKeyForTenantCloudInstance(account: string, domain?: string, body?: IdpTenantProvisioningModel, options?: any): AxiosPromise<IdpTenantPairingKeyModel> {
            return TenantProvisioningApiFp(configuration).reissueApiKeyForTenantCloudInstance(account, domain, body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary updateIdentityPlatformTenant
         * @param {string} account Account name
         * @param {IdpTenantProvisioningModel} [body] New tenant provisioning model
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateIdentityPlatformTenant(account: string, body?: IdpTenantProvisioningModel, options?: any): AxiosPromise<IdpTenantProvisioningModel> {
            return TenantProvisioningApiFp(configuration).updateIdentityPlatformTenant(account, body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TenantProvisioningApi - interface
 * @export
 * @interface TenantProvisioningApi
 */
export interface TenantProvisioningApiInterface {
    /**
     * 
     * @summary createIdentityPlatformTenant
     * @param {IdpTenantProvisioningModel} [body] Tenant create model
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantProvisioningApiInterface
     */
    createIdentityPlatformTenant(body?: IdpTenantProvisioningModel, options?: any): AxiosPromise<IdpTenantProvisioningModel>;

    /**
     * 
     * @summary deleteApiKeyForTenantCloudInstance
     * @param {string} account Account name
     * @param {string} [domain] Domain
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantProvisioningApiInterface
     */
    deleteApiKeyForTenantCloudInstance(account: string, domain?: string, options?: any): AxiosPromise<void>;

    /**
     * 
     * @summary deleteIdentityPlatformTenant
     * @param {string} account Account name
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantProvisioningApiInterface
     */
    deleteIdentityPlatformTenant(account: string, options?: any): AxiosPromise<void>;

    /**
     * 
     * @summary getApiKeyForTenantCloudInstance
     * @param {string} account Account name
     * @param {string} [domain] Domain
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantProvisioningApiInterface
     */
    getApiKeyForTenantCloudInstance(account: string, domain?: string, options?: any): AxiosPromise<IdpTenantPairingKeyModel>;

    /**
     * 
     * @summary getIdentityPlatformTenant
     * @param {string} account Account name
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantProvisioningApiInterface
     */
    getIdentityPlatformTenant(account: string, options?: any): AxiosPromise<IdpTenantProvisioningModel>;

    /**
     * 
     * @summary issueApiKeyForTenantCloudInstance
     * @param {string} account Account name
     * @param {string} [domain] Domain
     * @param {IdpTenantProvisioningModel} [body] Tenant api key model - ignored
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantProvisioningApiInterface
     */
    issueApiKeyForTenantCloudInstance(account: string, domain?: string, body?: IdpTenantProvisioningModel, options?: any): AxiosPromise<IdpTenantPairingKeyModel>;

    /**
     * 
     * @summary reissueApiKeyForTenantCloudInstance
     * @param {string} account Account name
     * @param {string} [domain] Domain
     * @param {IdpTenantProvisioningModel} [body] Tenant api key model - ignored
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantProvisioningApiInterface
     */
    reissueApiKeyForTenantCloudInstance(account: string, domain?: string, body?: IdpTenantProvisioningModel, options?: any): AxiosPromise<IdpTenantPairingKeyModel>;

    /**
     * 
     * @summary updateIdentityPlatformTenant
     * @param {string} account Account name
     * @param {IdpTenantProvisioningModel} [body] New tenant provisioning model
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantProvisioningApiInterface
     */
    updateIdentityPlatformTenant(account: string, body?: IdpTenantProvisioningModel, options?: any): AxiosPromise<IdpTenantProvisioningModel>;

}

/**
 * TenantProvisioningApi - object-oriented interface
 * @export
 * @class TenantProvisioningApi
 * @extends {BaseAPI}
 */
export class TenantProvisioningApi extends BaseAPI implements TenantProvisioningApiInterface {
    /**
     * 
     * @summary createIdentityPlatformTenant
     * @param {IdpTenantProvisioningModel} [body] Tenant create model
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantProvisioningApi
     */
    public createIdentityPlatformTenant(body?: IdpTenantProvisioningModel, options?: any) {
        return TenantProvisioningApiFp(this.configuration).createIdentityPlatformTenant(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary deleteApiKeyForTenantCloudInstance
     * @param {string} account Account name
     * @param {string} [domain] Domain
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantProvisioningApi
     */
    public deleteApiKeyForTenantCloudInstance(account: string, domain?: string, options?: any) {
        return TenantProvisioningApiFp(this.configuration).deleteApiKeyForTenantCloudInstance(account, domain, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary deleteIdentityPlatformTenant
     * @param {string} account Account name
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantProvisioningApi
     */
    public deleteIdentityPlatformTenant(account: string, options?: any) {
        return TenantProvisioningApiFp(this.configuration).deleteIdentityPlatformTenant(account, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary getApiKeyForTenantCloudInstance
     * @param {string} account Account name
     * @param {string} [domain] Domain
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantProvisioningApi
     */
    public getApiKeyForTenantCloudInstance(account: string, domain?: string, options?: any) {
        return TenantProvisioningApiFp(this.configuration).getApiKeyForTenantCloudInstance(account, domain, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary getIdentityPlatformTenant
     * @param {string} account Account name
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantProvisioningApi
     */
    public getIdentityPlatformTenant(account: string, options?: any) {
        return TenantProvisioningApiFp(this.configuration).getIdentityPlatformTenant(account, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary issueApiKeyForTenantCloudInstance
     * @param {string} account Account name
     * @param {string} [domain] Domain
     * @param {IdpTenantProvisioningModel} [body] Tenant api key model - ignored
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantProvisioningApi
     */
    public issueApiKeyForTenantCloudInstance(account: string, domain?: string, body?: IdpTenantProvisioningModel, options?: any) {
        return TenantProvisioningApiFp(this.configuration).issueApiKeyForTenantCloudInstance(account, domain, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary reissueApiKeyForTenantCloudInstance
     * @param {string} account Account name
     * @param {string} [domain] Domain
     * @param {IdpTenantProvisioningModel} [body] Tenant api key model - ignored
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantProvisioningApi
     */
    public reissueApiKeyForTenantCloudInstance(account: string, domain?: string, body?: IdpTenantProvisioningModel, options?: any) {
        return TenantProvisioningApiFp(this.configuration).reissueApiKeyForTenantCloudInstance(account, domain, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary updateIdentityPlatformTenant
     * @param {string} account Account name
     * @param {IdpTenantProvisioningModel} [body] New tenant provisioning model
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantProvisioningApi
     */
    public updateIdentityPlatformTenant(account: string, body?: IdpTenantProvisioningModel, options?: any) {
        return TenantProvisioningApiFp(this.configuration).updateIdentityPlatformTenant(account, body, options).then((request) => request(this.axios, this.basePath));
    }

}


/**
 * UsersApi - axios parameter creator
 * @export
 */
export const UsersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary getCurrentUser
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCurrentUser: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/users`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary lookupTenantId
         * @param {string} tenantName tenantName
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        lookupTenantId: async (tenantName: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantName' is not null or undefined
            if (tenantName === null || tenantName === undefined) {
                throw new RequiredError('tenantName','Required parameter tenantName was null or undefined when calling lookupTenantId.');
            }
            const localVarPath = `/lookuptenant`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (tenantName !== undefined) {
                localVarQueryParameter['tenantName'] = tenantName;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary redirectAuthenticatedUser
         * @param {string} ticketGrantingTicketSignedHex ticketGrantingTicketSignedHex
         * @param {string} returnUrl returnUrl
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        redirectAuthenticatedUser: async (ticketGrantingTicketSignedHex: string, returnUrl: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'ticketGrantingTicketSignedHex' is not null or undefined
            if (ticketGrantingTicketSignedHex === null || ticketGrantingTicketSignedHex === undefined) {
                throw new RequiredError('ticketGrantingTicketSignedHex','Required parameter ticketGrantingTicketSignedHex was null or undefined when calling redirectAuthenticatedUser.');
            }
            // verify required parameter 'returnUrl' is not null or undefined
            if (returnUrl === null || returnUrl === undefined) {
                throw new RequiredError('returnUrl','Required parameter returnUrl was null or undefined when calling redirectAuthenticatedUser.');
            }
            const localVarPath = `/api/redirect`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (ticketGrantingTicketSignedHex !== undefined) {
                localVarQueryParameter['ticketGrantingTicketSignedHex'] = ticketGrantingTicketSignedHex;
            }

            if (returnUrl !== undefined) {
                localVarQueryParameter['returnUrl'] = returnUrl;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary registerNewUser
         * @param {string} [region] GCP region
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        registerNewUser: async (region?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/users`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (region !== undefined) {
                localVarQueryParameter['region'] = region;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UsersApi - functional programming interface
 * @export
 */
export const UsersApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary getCurrentUser
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCurrentUser(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserModel>> {
            const localVarAxiosArgs = await UsersApiAxiosParamCreator(configuration).getCurrentUser(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary lookupTenantId
         * @param {string} tenantName tenantName
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async lookupTenantId(tenantName: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await UsersApiAxiosParamCreator(configuration).lookupTenantId(tenantName, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary redirectAuthenticatedUser
         * @param {string} ticketGrantingTicketSignedHex ticketGrantingTicketSignedHex
         * @param {string} returnUrl returnUrl
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async redirectAuthenticatedUser(ticketGrantingTicketSignedHex: string, returnUrl: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await UsersApiAxiosParamCreator(configuration).redirectAuthenticatedUser(ticketGrantingTicketSignedHex, returnUrl, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary registerNewUser
         * @param {string} [region] GCP region
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async registerNewUser(region?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await UsersApiAxiosParamCreator(configuration).registerNewUser(region, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * UsersApi - factory interface
 * @export
 */
export const UsersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary getCurrentUser
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCurrentUser(options?: any): AxiosPromise<UserModel> {
            return UsersApiFp(configuration).getCurrentUser(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary lookupTenantId
         * @param {string} tenantName tenantName
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        lookupTenantId(tenantName: string, options?: any): AxiosPromise<string> {
            return UsersApiFp(configuration).lookupTenantId(tenantName, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary redirectAuthenticatedUser
         * @param {string} ticketGrantingTicketSignedHex ticketGrantingTicketSignedHex
         * @param {string} returnUrl returnUrl
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        redirectAuthenticatedUser(ticketGrantingTicketSignedHex: string, returnUrl: string, options?: any): AxiosPromise<string> {
            return UsersApiFp(configuration).redirectAuthenticatedUser(ticketGrantingTicketSignedHex, returnUrl, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary registerNewUser
         * @param {string} [region] GCP region
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        registerNewUser(region?: string, options?: any): AxiosPromise<string> {
            return UsersApiFp(configuration).registerNewUser(region, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UsersApi - interface
 * @export
 * @interface UsersApi
 */
export interface UsersApiInterface {
    /**
     * 
     * @summary getCurrentUser
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApiInterface
     */
    getCurrentUser(options?: any): AxiosPromise<UserModel>;

    /**
     * 
     * @summary lookupTenantId
     * @param {string} tenantName tenantName
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApiInterface
     */
    lookupTenantId(tenantName: string, options?: any): AxiosPromise<string>;

    /**
     * 
     * @summary redirectAuthenticatedUser
     * @param {string} ticketGrantingTicketSignedHex ticketGrantingTicketSignedHex
     * @param {string} returnUrl returnUrl
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApiInterface
     */
    redirectAuthenticatedUser(ticketGrantingTicketSignedHex: string, returnUrl: string, options?: any): AxiosPromise<string>;

    /**
     * 
     * @summary registerNewUser
     * @param {string} [region] GCP region
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApiInterface
     */
    registerNewUser(region?: string, options?: any): AxiosPromise<string>;

}

/**
 * UsersApi - object-oriented interface
 * @export
 * @class UsersApi
 * @extends {BaseAPI}
 */
export class UsersApi extends BaseAPI implements UsersApiInterface {
    /**
     * 
     * @summary getCurrentUser
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public getCurrentUser(options?: any) {
        return UsersApiFp(this.configuration).getCurrentUser(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary lookupTenantId
     * @param {string} tenantName tenantName
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public lookupTenantId(tenantName: string, options?: any) {
        return UsersApiFp(this.configuration).lookupTenantId(tenantName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary redirectAuthenticatedUser
     * @param {string} ticketGrantingTicketSignedHex ticketGrantingTicketSignedHex
     * @param {string} returnUrl returnUrl
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public redirectAuthenticatedUser(ticketGrantingTicketSignedHex: string, returnUrl: string, options?: any) {
        return UsersApiFp(this.configuration).redirectAuthenticatedUser(ticketGrantingTicketSignedHex, returnUrl, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary registerNewUser
     * @param {string} [region] GCP region
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public registerNewUser(region?: string, options?: any) {
        return UsersApiFp(this.configuration).registerNewUser(region, options).then((request) => request(this.axios, this.basePath));
    }

}


/**
 * WeConnectWebHookApi - axios parameter creator
 * @export
 */
export const WeConnectWebHookApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary weConnectOnContactEmailCaptured
         * @param {string} key 
         * @param {string} account 
         * @param {WeConnectContact} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        weConnectOnContactEmailCaptured: async (key: string, account: string, body?: WeConnectContact, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'key' is not null or undefined
            if (key === null || key === undefined) {
                throw new RequiredError('key','Required parameter key was null or undefined when calling weConnectOnContactEmailCaptured.');
            }
            // verify required parameter 'account' is not null or undefined
            if (account === null || account === undefined) {
                throw new RequiredError('account','Required parameter account was null or undefined when calling weConnectOnContactEmailCaptured.');
            }
            const localVarPath = `/api/weconnect/contactemailcaptured`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (key !== undefined) {
                localVarQueryParameter['key'] = key;
            }

            if (account !== undefined) {
                localVarQueryParameter['account'] = account;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary weConnectOnContactMarkedAsFollowUp
         * @param {string} key 
         * @param {string} account 
         * @param {WeConnectContact} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        weConnectOnContactMarkedAsFollowUp: async (key: string, account: string, body?: WeConnectContact, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'key' is not null or undefined
            if (key === null || key === undefined) {
                throw new RequiredError('key','Required parameter key was null or undefined when calling weConnectOnContactMarkedAsFollowUp.');
            }
            // verify required parameter 'account' is not null or undefined
            if (account === null || account === undefined) {
                throw new RequiredError('account','Required parameter account was null or undefined when calling weConnectOnContactMarkedAsFollowUp.');
            }
            const localVarPath = `/api/weconnect/markedasfollowup`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (key !== undefined) {
                localVarQueryParameter['key'] = key;
            }

            if (account !== undefined) {
                localVarQueryParameter['account'] = account;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary weConnectOnContactMarkedAsLead
         * @param {string} key 
         * @param {string} account 
         * @param {WeConnectContact} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        weConnectOnContactMarkedAsLead: async (key: string, account: string, body?: WeConnectContact, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'key' is not null or undefined
            if (key === null || key === undefined) {
                throw new RequiredError('key','Required parameter key was null or undefined when calling weConnectOnContactMarkedAsLead.');
            }
            // verify required parameter 'account' is not null or undefined
            if (account === null || account === undefined) {
                throw new RequiredError('account','Required parameter account was null or undefined when calling weConnectOnContactMarkedAsLead.');
            }
            const localVarPath = `/api/weconnect/markedaslead`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (key !== undefined) {
                localVarQueryParameter['key'] = key;
            }

            if (account !== undefined) {
                localVarQueryParameter['account'] = account;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary weConnectOnContactPhoneCaptured
         * @param {string} key 
         * @param {string} account 
         * @param {WeConnectContact} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        weConnectOnContactPhoneCaptured: async (key: string, account: string, body?: WeConnectContact, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'key' is not null or undefined
            if (key === null || key === undefined) {
                throw new RequiredError('key','Required parameter key was null or undefined when calling weConnectOnContactPhoneCaptured.');
            }
            // verify required parameter 'account' is not null or undefined
            if (account === null || account === undefined) {
                throw new RequiredError('account','Required parameter account was null or undefined when calling weConnectOnContactPhoneCaptured.');
            }
            const localVarPath = `/api/weconnect/contactphonecaptured`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (key !== undefined) {
                localVarQueryParameter['key'] = key;
            }

            if (account !== undefined) {
                localVarQueryParameter['account'] = account;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary weConnectOnContactReplied
         * @param {string} key 
         * @param {string} account 
         * @param {ContactRepliedToCampaignMessage} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        weConnectOnContactReplied: async (key: string, account: string, body?: ContactRepliedToCampaignMessage, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'key' is not null or undefined
            if (key === null || key === undefined) {
                throw new RequiredError('key','Required parameter key was null or undefined when calling weConnectOnContactReplied.');
            }
            // verify required parameter 'account' is not null or undefined
            if (account === null || account === undefined) {
                throw new RequiredError('account','Required parameter account was null or undefined when calling weConnectOnContactReplied.');
            }
            const localVarPath = `/api/weconnect/contactreplied`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (key !== undefined) {
                localVarQueryParameter['key'] = key;
            }

            if (account !== undefined) {
                localVarQueryParameter['account'] = account;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary weConnectOnContactTagged
         * @param {string} key 
         * @param {string} account 
         * @param {ContactTaggedMessage} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        weConnectOnContactTagged: async (key: string, account: string, body?: ContactTaggedMessage, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'key' is not null or undefined
            if (key === null || key === undefined) {
                throw new RequiredError('key','Required parameter key was null or undefined when calling weConnectOnContactTagged.');
            }
            // verify required parameter 'account' is not null or undefined
            if (account === null || account === undefined) {
                throw new RequiredError('account','Required parameter account was null or undefined when calling weConnectOnContactTagged.');
            }
            const localVarPath = `/api/weconnect/contacttagged`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (key !== undefined) {
                localVarQueryParameter['key'] = key;
            }

            if (account !== undefined) {
                localVarQueryParameter['account'] = account;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary weConnectOnNoteAddedToContact
         * @param {string} key 
         * @param {string} account 
         * @param {NoteSetOnContactMessage} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        weConnectOnNoteAddedToContact: async (key: string, account: string, body?: NoteSetOnContactMessage, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'key' is not null or undefined
            if (key === null || key === undefined) {
                throw new RequiredError('key','Required parameter key was null or undefined when calling weConnectOnNoteAddedToContact.');
            }
            // verify required parameter 'account' is not null or undefined
            if (account === null || account === undefined) {
                throw new RequiredError('account','Required parameter account was null or undefined when calling weConnectOnNoteAddedToContact.');
            }
            const localVarPath = `/api/weconnect/noteadded`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (key !== undefined) {
                localVarQueryParameter['key'] = key;
            }

            if (account !== undefined) {
                localVarQueryParameter['account'] = account;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * WeConnectWebHookApi - functional programming interface
 * @export
 */
export const WeConnectWebHookApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary weConnectOnContactEmailCaptured
         * @param {string} key 
         * @param {string} account 
         * @param {WeConnectContact} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async weConnectOnContactEmailCaptured(key: string, account: string, body?: WeConnectContact, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PipedrivePersonIds>> {
            const localVarAxiosArgs = await WeConnectWebHookApiAxiosParamCreator(configuration).weConnectOnContactEmailCaptured(key, account, body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary weConnectOnContactMarkedAsFollowUp
         * @param {string} key 
         * @param {string} account 
         * @param {WeConnectContact} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async weConnectOnContactMarkedAsFollowUp(key: string, account: string, body?: WeConnectContact, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PipedrivePersonIds>> {
            const localVarAxiosArgs = await WeConnectWebHookApiAxiosParamCreator(configuration).weConnectOnContactMarkedAsFollowUp(key, account, body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary weConnectOnContactMarkedAsLead
         * @param {string} key 
         * @param {string} account 
         * @param {WeConnectContact} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async weConnectOnContactMarkedAsLead(key: string, account: string, body?: WeConnectContact, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PipedrivePersonIds>> {
            const localVarAxiosArgs = await WeConnectWebHookApiAxiosParamCreator(configuration).weConnectOnContactMarkedAsLead(key, account, body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary weConnectOnContactPhoneCaptured
         * @param {string} key 
         * @param {string} account 
         * @param {WeConnectContact} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async weConnectOnContactPhoneCaptured(key: string, account: string, body?: WeConnectContact, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PipedrivePersonIds>> {
            const localVarAxiosArgs = await WeConnectWebHookApiAxiosParamCreator(configuration).weConnectOnContactPhoneCaptured(key, account, body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary weConnectOnContactReplied
         * @param {string} key 
         * @param {string} account 
         * @param {ContactRepliedToCampaignMessage} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async weConnectOnContactReplied(key: string, account: string, body?: ContactRepliedToCampaignMessage, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PipedrivePersonIds>> {
            const localVarAxiosArgs = await WeConnectWebHookApiAxiosParamCreator(configuration).weConnectOnContactReplied(key, account, body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary weConnectOnContactTagged
         * @param {string} key 
         * @param {string} account 
         * @param {ContactTaggedMessage} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async weConnectOnContactTagged(key: string, account: string, body?: ContactTaggedMessage, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PipedrivePersonIds>> {
            const localVarAxiosArgs = await WeConnectWebHookApiAxiosParamCreator(configuration).weConnectOnContactTagged(key, account, body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary weConnectOnNoteAddedToContact
         * @param {string} key 
         * @param {string} account 
         * @param {NoteSetOnContactMessage} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async weConnectOnNoteAddedToContact(key: string, account: string, body?: NoteSetOnContactMessage, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PipedrivePersonIds>> {
            const localVarAxiosArgs = await WeConnectWebHookApiAxiosParamCreator(configuration).weConnectOnNoteAddedToContact(key, account, body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * WeConnectWebHookApi - factory interface
 * @export
 */
export const WeConnectWebHookApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary weConnectOnContactEmailCaptured
         * @param {string} key 
         * @param {string} account 
         * @param {WeConnectContact} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        weConnectOnContactEmailCaptured(key: string, account: string, body?: WeConnectContact, options?: any): AxiosPromise<PipedrivePersonIds> {
            return WeConnectWebHookApiFp(configuration).weConnectOnContactEmailCaptured(key, account, body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary weConnectOnContactMarkedAsFollowUp
         * @param {string} key 
         * @param {string} account 
         * @param {WeConnectContact} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        weConnectOnContactMarkedAsFollowUp(key: string, account: string, body?: WeConnectContact, options?: any): AxiosPromise<PipedrivePersonIds> {
            return WeConnectWebHookApiFp(configuration).weConnectOnContactMarkedAsFollowUp(key, account, body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary weConnectOnContactMarkedAsLead
         * @param {string} key 
         * @param {string} account 
         * @param {WeConnectContact} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        weConnectOnContactMarkedAsLead(key: string, account: string, body?: WeConnectContact, options?: any): AxiosPromise<PipedrivePersonIds> {
            return WeConnectWebHookApiFp(configuration).weConnectOnContactMarkedAsLead(key, account, body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary weConnectOnContactPhoneCaptured
         * @param {string} key 
         * @param {string} account 
         * @param {WeConnectContact} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        weConnectOnContactPhoneCaptured(key: string, account: string, body?: WeConnectContact, options?: any): AxiosPromise<PipedrivePersonIds> {
            return WeConnectWebHookApiFp(configuration).weConnectOnContactPhoneCaptured(key, account, body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary weConnectOnContactReplied
         * @param {string} key 
         * @param {string} account 
         * @param {ContactRepliedToCampaignMessage} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        weConnectOnContactReplied(key: string, account: string, body?: ContactRepliedToCampaignMessage, options?: any): AxiosPromise<PipedrivePersonIds> {
            return WeConnectWebHookApiFp(configuration).weConnectOnContactReplied(key, account, body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary weConnectOnContactTagged
         * @param {string} key 
         * @param {string} account 
         * @param {ContactTaggedMessage} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        weConnectOnContactTagged(key: string, account: string, body?: ContactTaggedMessage, options?: any): AxiosPromise<PipedrivePersonIds> {
            return WeConnectWebHookApiFp(configuration).weConnectOnContactTagged(key, account, body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary weConnectOnNoteAddedToContact
         * @param {string} key 
         * @param {string} account 
         * @param {NoteSetOnContactMessage} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        weConnectOnNoteAddedToContact(key: string, account: string, body?: NoteSetOnContactMessage, options?: any): AxiosPromise<PipedrivePersonIds> {
            return WeConnectWebHookApiFp(configuration).weConnectOnNoteAddedToContact(key, account, body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * WeConnectWebHookApi - interface
 * @export
 * @interface WeConnectWebHookApi
 */
export interface WeConnectWebHookApiInterface {
    /**
     * 
     * @summary weConnectOnContactEmailCaptured
     * @param {string} key 
     * @param {string} account 
     * @param {WeConnectContact} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WeConnectWebHookApiInterface
     */
    weConnectOnContactEmailCaptured(key: string, account: string, body?: WeConnectContact, options?: any): AxiosPromise<PipedrivePersonIds>;

    /**
     * 
     * @summary weConnectOnContactMarkedAsFollowUp
     * @param {string} key 
     * @param {string} account 
     * @param {WeConnectContact} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WeConnectWebHookApiInterface
     */
    weConnectOnContactMarkedAsFollowUp(key: string, account: string, body?: WeConnectContact, options?: any): AxiosPromise<PipedrivePersonIds>;

    /**
     * 
     * @summary weConnectOnContactMarkedAsLead
     * @param {string} key 
     * @param {string} account 
     * @param {WeConnectContact} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WeConnectWebHookApiInterface
     */
    weConnectOnContactMarkedAsLead(key: string, account: string, body?: WeConnectContact, options?: any): AxiosPromise<PipedrivePersonIds>;

    /**
     * 
     * @summary weConnectOnContactPhoneCaptured
     * @param {string} key 
     * @param {string} account 
     * @param {WeConnectContact} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WeConnectWebHookApiInterface
     */
    weConnectOnContactPhoneCaptured(key: string, account: string, body?: WeConnectContact, options?: any): AxiosPromise<PipedrivePersonIds>;

    /**
     * 
     * @summary weConnectOnContactReplied
     * @param {string} key 
     * @param {string} account 
     * @param {ContactRepliedToCampaignMessage} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WeConnectWebHookApiInterface
     */
    weConnectOnContactReplied(key: string, account: string, body?: ContactRepliedToCampaignMessage, options?: any): AxiosPromise<PipedrivePersonIds>;

    /**
     * 
     * @summary weConnectOnContactTagged
     * @param {string} key 
     * @param {string} account 
     * @param {ContactTaggedMessage} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WeConnectWebHookApiInterface
     */
    weConnectOnContactTagged(key: string, account: string, body?: ContactTaggedMessage, options?: any): AxiosPromise<PipedrivePersonIds>;

    /**
     * 
     * @summary weConnectOnNoteAddedToContact
     * @param {string} key 
     * @param {string} account 
     * @param {NoteSetOnContactMessage} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WeConnectWebHookApiInterface
     */
    weConnectOnNoteAddedToContact(key: string, account: string, body?: NoteSetOnContactMessage, options?: any): AxiosPromise<PipedrivePersonIds>;

}

/**
 * WeConnectWebHookApi - object-oriented interface
 * @export
 * @class WeConnectWebHookApi
 * @extends {BaseAPI}
 */
export class WeConnectWebHookApi extends BaseAPI implements WeConnectWebHookApiInterface {
    /**
     * 
     * @summary weConnectOnContactEmailCaptured
     * @param {string} key 
     * @param {string} account 
     * @param {WeConnectContact} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WeConnectWebHookApi
     */
    public weConnectOnContactEmailCaptured(key: string, account: string, body?: WeConnectContact, options?: any) {
        return WeConnectWebHookApiFp(this.configuration).weConnectOnContactEmailCaptured(key, account, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary weConnectOnContactMarkedAsFollowUp
     * @param {string} key 
     * @param {string} account 
     * @param {WeConnectContact} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WeConnectWebHookApi
     */
    public weConnectOnContactMarkedAsFollowUp(key: string, account: string, body?: WeConnectContact, options?: any) {
        return WeConnectWebHookApiFp(this.configuration).weConnectOnContactMarkedAsFollowUp(key, account, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary weConnectOnContactMarkedAsLead
     * @param {string} key 
     * @param {string} account 
     * @param {WeConnectContact} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WeConnectWebHookApi
     */
    public weConnectOnContactMarkedAsLead(key: string, account: string, body?: WeConnectContact, options?: any) {
        return WeConnectWebHookApiFp(this.configuration).weConnectOnContactMarkedAsLead(key, account, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary weConnectOnContactPhoneCaptured
     * @param {string} key 
     * @param {string} account 
     * @param {WeConnectContact} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WeConnectWebHookApi
     */
    public weConnectOnContactPhoneCaptured(key: string, account: string, body?: WeConnectContact, options?: any) {
        return WeConnectWebHookApiFp(this.configuration).weConnectOnContactPhoneCaptured(key, account, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary weConnectOnContactReplied
     * @param {string} key 
     * @param {string} account 
     * @param {ContactRepliedToCampaignMessage} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WeConnectWebHookApi
     */
    public weConnectOnContactReplied(key: string, account: string, body?: ContactRepliedToCampaignMessage, options?: any) {
        return WeConnectWebHookApiFp(this.configuration).weConnectOnContactReplied(key, account, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary weConnectOnContactTagged
     * @param {string} key 
     * @param {string} account 
     * @param {ContactTaggedMessage} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WeConnectWebHookApi
     */
    public weConnectOnContactTagged(key: string, account: string, body?: ContactTaggedMessage, options?: any) {
        return WeConnectWebHookApiFp(this.configuration).weConnectOnContactTagged(key, account, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary weConnectOnNoteAddedToContact
     * @param {string} key 
     * @param {string} account 
     * @param {NoteSetOnContactMessage} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WeConnectWebHookApi
     */
    public weConnectOnNoteAddedToContact(key: string, account: string, body?: NoteSetOnContactMessage, options?: any) {
        return WeConnectWebHookApiFp(this.configuration).weConnectOnNoteAddedToContact(key, account, body, options).then((request) => request(this.axios, this.basePath));
    }

}


