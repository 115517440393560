import firebase, { analytics, auth } from "../firebaseSingleton";
import { User, UserInfo } from "firebase/app";
import "firebase/auth";
import axios, {AxiosResponse} from "axios";
import moment from "moment";
import { UserApiClient } from "./apiClient";
import {UserModel} from "../api";


export interface Authentication {
  signUpWithEmailAddressAndPassword(
    emailAddress: string,
    password: string
  ): Promise<User>;
  signIn(emailAddress: string, password: string): Promise<User>;
  sendSignInLinkToEmail(emailAddress: string): Promise<void>;
  signInWithEmailLink(
    emailAddress: string,
    emailLink: string
  ): Promise<firebase.auth.UserCredential>;
  signUpWithAuthProvider(providerId: string): Promise<User>;
  signInWithAuthProvider(providerId: string): Promise<User>;
  linkAuthProvider(providerId: string): Promise<firebase.auth.UserCredential>;
  unlinkAuthProvider(providerId: string): Promise<User>;
  authProviderData(providerId: string): UserInfo | null | undefined;
  signOut(): Promise<void>;
  resetPassword(emailAddress: string): Promise<void>;
  changeEmailAddress(emailAddress: string): Promise<void>;
  changePassword(password: string): Promise<void>;
  verifyEmailAddress(): Promise<void>;
  deleteAccount(): Promise<void>;
  getRoles(): Promise<any>;
  isAdmin(): Promise<boolean>;
  isPremium(): Promise<boolean>;
}

const authentication: Authentication = {} as Authentication;

authentication.signUpWithEmailAddressAndPassword = (emailAddress, password) => {
  return new Promise((resolve, reject) => {
    if (!emailAddress || !password) {
      reject();
      return;
    }

    if (auth.currentUser) {
      reject();
      return;
    }

    auth
      .createUserWithEmailAndPassword(emailAddress, password)
      .then((value) => {
        const user = value.user;
        if (!user) {
          reject();
          return;
        }

        const uid = user.uid;
        if (!uid) {
          reject();
          return;
        }

        user.getIdToken().then((token: string) => {
          axios.defaults.headers.common["Authorization"] = "Bearer " + token;

          UserApiClient.registerNewUser().then((value: AxiosResponse<string>) => {
              analytics.logEvent('sign_up', {
                method: 'password'
              });

              resolve(user);
            })
            .catch((error: any) => {
              auth.signOut();
              reject("You are not allowed to access DQO, please sign up for a free account or request access from your administrator");
            });        
        }).catch(reason => {
          auth.signOut();
          reject(reason ?? 'New user cannot be registered' );
        });
      });
  });
};

authentication.signIn = (
  emailAddress: string,
  password: string
): Promise<User> => {
  return new Promise((resolve, reject) => {
    if (!emailAddress || !password) {
      reject('no email or password provided');
      return;
    }

    if (auth.currentUser) {
      reject('already logged in');
      return;
    }

    auth
      .signInWithEmailAndPassword(emailAddress, password)
      .then((value) => {
        const user = value.user;
        if (!user) {
          reject('user not found');
          return;
        }

        const uid: string = user.uid;
        if (!uid) {
          reject('user not found');
          return;
        }

        user.getIdToken().then((token: string) => {
          axios.defaults.headers.common["Authorization"] = "Bearer " + token;

          UserApiClient.getCurrentUser().then((response: AxiosResponse<UserModel>) => {
            if(response.data.userId === user.uid) {
              analytics.logEvent("login", {
                method: "password"
              });
              
              if (response.data.tenantGroupId === undefined || response.data.tenantGroupId == null) {
                auth.signOut();
                reject('User is not provisioned correctly, please contact support.');
                return;
              }

              resolve(user);
            } else {
              auth.signOut();
              reject('user doesn\'t match');
            }
          })
          .catch((error: any) => {
            auth.signOut();
            reject("You are not allowed to access DQO, please sign up for a free account or request access from your administrator");
          });
        });
      })
      .catch((reason) => {
        auth.signOut();
        reject(reason ?? "You are not authorized to access DQO, please contact your administrator");
      });
  });
};

authentication.sendSignInLinkToEmail = (emailAddress) => {
  return new Promise((resolve, reject) => {
    if (!emailAddress) {
      reject();
      return;
    }

    if (auth.currentUser) {
      reject();
      return;
    }

    const actionCodeSettings: firebase.auth.ActionCodeSettings = {
      url: process.env.REACT_APP_HOMEPAGE as string,
      handleCodeInApp: true,
    };

    auth
      .sendSignInLinkToEmail(emailAddress, actionCodeSettings)
      .then((value) => {
        analytics.logEvent("send_sign_in_link_to_email");

        localStorage.setItem("emailAddress", emailAddress);

        resolve(value);
      })
      .catch((reason) => {
        reject(reason);
      });
  });
};

authentication.signInWithEmailLink = (emailAddress, emailLink) => {
  return new Promise((resolve, reject) => {
    if (!emailAddress || !emailLink) {
      reject();
      return;
    }
    if (auth.currentUser) {
      reject();
      return;
    }
    auth
      .signInWithEmailLink(emailAddress, emailLink)
      .then((value) => {
        analytics.logEvent("login", {
          method: "email-link",
        });

        localStorage.removeItem("emailAddress");
        resolve(value);
      })
      .catch((reason) => {
        reject(reason);
      });
  });
};

authentication.signUpWithAuthProvider = (providerId) => {
  return new Promise((resolve, reject) => {
    if (!providerId) {
      reject();
      return;
    }

    const provider = new firebase.auth.OAuthProvider(providerId);
    if (!provider) {
      reject();
      return;
    }

    if (auth.currentUser) {
      reject();
      return;
    }

    auth
        .signInWithPopup(provider)
        .then((value) => {
          const user = value.user;
          if (!user) {
            reject();
            return;
          }

          const uid = user.uid;
          if (!uid) {
            reject();
            return;
          }

          user.getIdToken().then((token: string) => {
            axios.defaults.headers.common["Authorization"] = "Bearer " + token;

            UserApiClient.registerNewUser().then((value: AxiosResponse<string>) => {
              analytics.logEvent("login", {
                method: providerId
              });

              resolve(user);
            })
            .catch((error: any) => {
              auth.signOut();
              reject("You are not allowed to access DQO, please sign up for a free account or request access from your administrator");
            });
          });
        })
        .catch((reason) => {
          auth.signOut();
          reject(reason ?? "You are not authorized to access DQO, please contact your administrator");
        });
  });
};

authentication.signInWithAuthProvider = (providerId) => {
  return new Promise((resolve, reject) => {
    if (!providerId) {
      reject();
      return;
    }

    const provider = new firebase.auth.OAuthProvider(providerId);
    if (!provider) {
      reject();
      return;
    }

    if (auth.currentUser) {
      reject();
      return;
    }
    
    auth
      .signInWithPopup(provider)
      .then((value) => {
        const user = value.user;
        if (!user) {
          reject();
          return;
        }

        const uid = user.uid;
        if (!uid) {
          reject();
          return;
        }

        user.getIdToken().then((token: string) => {
          axios.defaults.headers.common["Authorization"] = "Bearer " + token;
          UserApiClient.getCurrentUser().then((response: AxiosResponse<UserModel>) => {
            if(response.data.userId === user.uid) {
              analytics.logEvent("login", {
                method: "password"
              });

              if (response.data.tenantId === undefined || response.data.tenantGroupId == null) {
                auth.signOut()
                    .finally(() => {
                      reject('You are not registered, please register first using the Sign Up button');
                    });
                return;
              }

              resolve(user);
            } else {
              auth.signOut()
                  .finally(() => {
                    reject('You are not registered, please register first using the Sign Up button');
                  });
            }
          })
          .catch((error: any) => {
            auth.signOut();
            reject("You are not allowed to access DQO, please sign up for a free account or request access from your administrator");
          });
        });
      })
      .catch((reason) => {
        auth.signOut();
        reject("You are not authorized to access DQO, please contact your administrator");
      });
  });
};

authentication.linkAuthProvider = (providerId) => {
  return new Promise((resolve, reject) => {
    if (!providerId) {
      reject();
      return;
    }

    const provider = new firebase.auth.OAuthProvider(providerId);
    if (!provider) {
      reject();
      return;
    }

    const currentUser = auth.currentUser;
    if (!currentUser) {
      reject();
      return;
    }

    currentUser
      .linkWithPopup(provider)
      .then((value) => {
        analytics.logEvent("link_auth_provider", {
          providerId: providerId,
        });

        resolve(value);
      })
      .catch((reason) => {
        reject(reason);
      });
  });
};

authentication.unlinkAuthProvider = (providerId) => {
  return new Promise((resolve, reject) => {
    if (!providerId) {
      reject();
      return;
    }

    const currentUser = auth.currentUser;
    if (!currentUser) {
      reject();
      return;
    }

    currentUser
      .unlink(providerId)
      .then((value) => {
        analytics.logEvent("unlink_auth_provider", {
          providerId: providerId,
        });
        resolve(value);
      })
      .catch((reason) => {
        reject(reason);
      });
  });
};

authentication.authProviderData = (providerId) => {
  if (!providerId) {
    return;
  }

  const currentUser = auth.currentUser;
  if (!currentUser) {
    return;
  }

  const providerData = currentUser.providerData;
  if (!providerData) {
    return;
  }
  return providerData.find(
    (authProvider) =>
      authProvider != null && authProvider.providerId === providerId
  );
};

authentication.signOut = () => {
  return new Promise((resolve, reject) => {
    const currentUser = auth.currentUser;
    if (!currentUser) {
      reject();
      return;
    }
    auth
      .signOut()
      .then((value) => {
        analytics.logEvent("sign_out");
        resolve(value);
      })
      .catch((reason) => {
        reject(reason);
      });
  });
};

authentication.resetPassword = (emailAddress) => {
  return new Promise((resolve, reject) => {
    if (!emailAddress) {
      reject();
      return;
    }

    if (auth.currentUser) {
      reject();
      return;
    }

    auth
      .sendPasswordResetEmail(emailAddress)
      .then((value) => {
        analytics.logEvent("reset_password");
        resolve(value);
      })
      .catch((reason) => {
        reject(reason);
      });
  });
};

authentication.changeEmailAddress = (emailAddress) => {
  return new Promise((resolve, reject) => {
    if (!emailAddress) {
      reject();
      return;
    }

    const currentUser = auth.currentUser;
    if (!currentUser) {
      reject();
      return;
    }

    const uid = currentUser.uid;
    if (!uid) {
      reject();
      return;
    }

    currentUser
      .updateEmail(emailAddress)
      .then((value) => {
        analytics.logEvent("change_email_address");
        resolve(value);
      })
      .catch((reason) => {
        reject(reason);
      });
  });
};

authentication.changePassword = (password) => {
  return new Promise((resolve, reject) => {
    if (!password) {
      reject();
      return;
    }

    const currentUser = auth.currentUser;
    if (!currentUser) {
      reject();
      return;
    }

    const uid = currentUser.uid;
    if (!uid) {
      reject();
      return;
    }

    currentUser
      .updatePassword(password)
      .then((value) => {
          analytics.logEvent("change_password");
          
          resolve(value);
      })
      .catch((reason) => {
        reject(reason);
      });
  });
};

authentication.verifyEmailAddress = () => {
  return new Promise((resolve, reject) => {
    const currentUser = auth.currentUser;

    if (!currentUser) {
      reject();
      return;
    }

    currentUser
      .sendEmailVerification()
      .then((value) => {
        analytics.logEvent("verify_email_address");

        resolve(value);
      })
      .catch((reason) => {
        reject(reason);
      });
  });
};

authentication.deleteAccount = () => {
  return new Promise((resolve, reject) => {
    const currentUser = auth.currentUser;

    if (!currentUser) {
      reject();
      return;
    }

    currentUser
      .delete()
      .then((value) => {
        analytics.logEvent("delete_account");

        resolve(value);
      })
      .catch((reason) => {
        reject(reason);
      });
  });
};

function getRoles(): Promise<any> {
  return new Promise((resolve, reject) => {
    const currentUser = auth.currentUser;

    if (!currentUser) {
      reject();
      return;
    }

    currentUser
      .getIdTokenResult()
      .then((idTokenResult) => {
        resolve(idTokenResult.claims.roles);
      })
      .catch((reason) => {
        reject(reason);
      });
  });
}

authentication.getRoles = getRoles;

authentication.isAdmin = () => {
  return new Promise((resolve, reject) => {
    getRoles()
      .then((value) => {
        resolve(value.includes("admin"));
      })
      .catch((reason) => {
        reject(reason);
      });
  });
};

authentication.isPremium = () => {
  return new Promise((resolve, reject) => {
    getRoles()
      .then((value) => {
        resolve(value.includes("premium"));
      })
      .catch((reason) => {
        reject(reason);
      });
  });
};

export default authentication;
