import React, { useEffect, useState } from 'react';
import { FileExchangeAPIKey, EmptyState } from 'components';
import { useParams } from 'react-router-dom';
import { ApiKeyRequestClient } from '../../services/apiClient';
import {AxiosResponse} from "axios";
import {ApiKeyRequestActionCreator} from "../../state/apikeyrequest/ApiKeyRequestActionCreator";
import axios from "axios";
import * as firebase from "firebase/app";
import "firebase/auth";

type RequestApiKeyParams = {
	apiKeyRequest: string;
}

const RequestApiKey = () => {
	const [apiKey, setApiKey] = useState<string>('');
	const { apiKeyRequest } = useParams<RequestApiKeyParams>();
	
	useEffect(() => {
		if (apiKeyRequest !== undefined) {
			firebase.auth().currentUser?.getIdToken()
			.then((token: string) => {
			    axios.defaults.headers.common["Authorization"] = "Bearer " + token;
//				axios.defaults.withCredentials = true;

				ApiKeyRequestClient.issueApiKey(apiKeyRequest).then(
					(response: AxiosResponse<string>) => {
						// TODO: we can show the api key...
						ApiKeyRequestActionCreator.apiKeyPicked();
						setApiKey(response.data);
					},
					(error) => {
						
					}
				);
			});
		}
	}, [apiKeyRequest]);
	
	return (
		<>
		  <EmptyState title="API Key was issued" />
		  <ul style={{listStyleType: 'none', padding: 0,}}>
		  	{apiKey !== '' && <FileExchangeAPIKey key={apiKey} />}
		  </ul>
		  <div
		    style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '80vh',
              fontSize: '24px',
            }}
          >
            <div>
              <p>You can close this window now,</p>
              <p>DQO has picked up your API key</p>
            </div>
          </div>
		</>
	);
}

export default RequestApiKey;