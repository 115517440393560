import React, { useEffect } from 'react';
import { dqoLoginStore } from "../../state/dqologin/DqoLoginStore";
import { DqoLoginState } from "../../state/dqologin/DqoLoginState";
import {DqoLoginActionCreator} from "../../state/dqologin/DqoLoginActionCreator";


const WrongTenant = () => {
  let dqoLoginState: DqoLoginState = dqoLoginStore.getState();

	useEffect(() => {
		DqoLoginActionCreator.wrongTenantClearState();
	}, [dqoLoginState]);
	
	return (
		  <div
		    style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '80vh',
              fontSize: '24px',
            }}
          >
            <div>
              <p>You have logged on to a wrong DQO Cloud account for a different customer.</p>
              <p>Please log out and log in again with the credentials that matches credentials of the DQO Cloud API Key used by your DQO instance.</p>
            </div>
      </div>
	);
}

export default WrongTenant;