
import React from 'react';
import { useHistory } from 'react-router-dom';
import useStyles from 'styles';

import logo_dqo from './logo/dqo_logo.png';
import logo_dqo_colour from './logo/dqo_logo.png';

type Logo = {
  background?: boolean;
  color?: boolean;
};

const Logo = ({
  background, color
}: Logo) => 
{
  const classes = useStyles();
  const history = useHistory();

  return (
    <div
      className={background ? classes.logoWithBackground : ''}
      style={{display: 'flex', flexFlow: 'column nowrap', justifyContent: 'center'}}>
      <img
        style={{
          width: '150px',
          marginLeft: '0.5rem',
          cursor: 'pointer'
        }}
        src={color ? logo_dqo_colour : logo_dqo}
        alt='DQO Logo'
        onClick={() => history.push('')}
      />
    </div>
  );
}

export default Logo;