import React, { useEffect } from 'react';
import { UserApiClient } from "../../services/apiClient";
import { dqoLoginStore } from "../../state/dqologin/DqoLoginStore";
import { DqoLoginState } from "../../state/dqologin/DqoLoginState";
import { DqoLoginActionCreator } from "../../state/dqologin/DqoLoginActionCreator";
import {useQueryParam, StringParam } from "use-query-params";
import axios from "axios";
import * as firebase from "firebase/app";
import "firebase/auth";

const IdentityProviderLogin = () => {
  let dqoLoginState: DqoLoginState = dqoLoginStore.getState();
  const [ tgt, setTgt] = useQueryParam("tgt", StringParam);
  const [ accountName, setAccountName] = useQueryParam("account", StringParam);
  const [ returnUrl, setReturnUrl] = useQueryParam("returnUrl", StringParam);

	useEffect(() => {
    if (tgt && returnUrl) {
      DqoLoginActionCreator.loginDqoUser(tgt, returnUrl, accountName);
      dqoLoginState = dqoLoginStore.getState();
    }

    if (
      dqoLoginState !== undefined &&
      dqoLoginState != null &&
      dqoLoginState.tgt &&
      dqoLoginState.returnUrl
    ) {

      let tgt = dqoLoginState.tgt;
      let returnUrl = dqoLoginState.returnUrl;

      // requesting return login url
      firebase.auth().currentUser?.getIdToken()
          .then((token: string) => {
              axios.defaults.headers.common["Authorization"] = "Bearer " + token;

              UserApiClient.redirectAuthenticatedUser(tgt, returnUrl)
                .then((response): void => {
                  DqoLoginActionCreator.redirectingToDqo();
                  window.location.replace(response.data);
                })
                .catch((response) => {
                  firebase.auth().signOut();
                  window.location.reload();
                });
          });
    } else {
      window.history.pushState(
        null,
        "DQO Cloud",
        "/"
      );
    }
 	}, [tgt, returnUrl]);
	
	return (
		<>
		  <div>
      </div>
		</>
	);
}

export default IdentityProviderLogin;